import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/loading";
import Sidebar from "../../components/Sidebar";
// import "./StudentUpdate.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DocumentUploadUi from '../../components/DocumentUploadUi.jsx';
import { clearError, getResourceAction, updateResourceAction } from "../../actions/resourceAction.js";
import { UPDATE_RESOURCE_RESET } from "../../constants/resourceConstant.js";
import RoleSelect from "./rolesUI.js";
import UsersSelect from "./UsersSelectUI.js";






const StudentUpdate = () => {
    const dispatch = useDispatch();

    const { error, isUpdated, loading } = useSelector(
        (state) => state.resourceUpdate
    );

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.users);


    const [fields, setFields] = useState([
        // { message: "", file: null, fileType: "" }
    ]);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);

    

    const [resourceData, setResourceData] = useState({
        message: '', link: ''
    })

    const {
        message, link
    } = resourceData;


    // get resource ---------------------------------------------------------------------
     
    const { id } = useParams();
    const { error:resErr, resource, loading:resLoading } = useSelector((state) => state.getResource);
    useEffect(() => {
        if (resErr) {
            toast.error(resErr);
            dispatch(clearError());
        }

    }, [resErr]);

    useEffect(() => {
        if (id) {
            dispatch(getResourceAction(id));
        }
    }, [id])
        // get resource end---------------------------------------------------------------------



    const studentCreateHandler = async (e) => {
        e.preventDefault();

        let ids = []
        users.forEach(item => {
            ids.push(item.value)
        });

        let myRoles = []
        roles.forEach(item => {
            myRoles.push(item.value)
        });
        
        
        let data = {
            textMessage:message,
            link,
            roles:myRoles, 
            ids,
            documentsFiles:fields
        };
        
        // console.log(data)


        dispatch(updateResourceAction(id,data));
    };



    const studentDataChangeHandler = e => {
        setResourceData({ ...resourceData, [e.target.name]: e.target.value })
    }


    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
            // setSelectedMultiFile([])
        }
        if (isUpdated) {
            toast.success("Resource has updated Successfully");
            // if(user?.role==='counsellor'){
            // }else{
            // }
            dispatch({ type: UPDATE_RESOURCE_RESET});
            navigate(-1);
        }
    }, [error, isUpdated, dispatch]);



    useEffect(() => {
        // setResourceData(university?.name)
        // setCountry(university?.country)

        setResourceData((prevData) => ({
            ...prevData,
            link: resource?.link,
        }));

        setResourceData((prevData) => ({
            ...prevData,
            message: resource?.textMessage,
        }));

    }, [resource])




    return (
        <div className="dashboardMain">
            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentUpdateBox">
                        <form action="" onSubmit={studentCreateHandler}>
                            <h3>Create New Resource</h3>


                            <>
                                <div className="assignContainer">

                                    <RoleSelect setRoles={setRoles} roles={roles} />
                                    <UsersSelect setUsers={setUsers} users={users} />

                                </div>

                                <br />
                                


                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p> Link</p>
                                        <input
                                            name="link"
                                            type="text" placeholder="value"
                                            value={link}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                </div>

                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Text message</p>

                                        <textarea name="message"
                                            placeholder="value"
                                            value={message}
                                            onChange={studentDataChangeHandler}
                                            id="" className="textArea"></textarea>
                                    </div>

                                </div>












                                <br />


                            </>


                            <DocumentUploadUi setFields={setFields} fields={fields} title="Upload Files" />



                            <div className="submitItem">
                                <input type="submit" value={"Update Resource"} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentUpdate;
