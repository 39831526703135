export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const INSTALL_MAIL_REQUEST = "INSTALL_MAIL_REQUEST"
export const INSTALL_MAIL_SUCCESS = "INSTALL_MAIL_SUCCESS"
export const INSTALL_MAIL_FAIL = "INSTALL_MAIL_FAIL"
export const INSTALL_MAIL_RESET = "INSTALL_MAIL_RESET"

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST"
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS"
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL"


export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_FAIL = "CREATE_USER_FAIL"
export const CREATE_USER_RESET = "CREATE_USER_RESET"


export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST"
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS"
export const INVITE_USER_FAIL = "INVITE_USER_FAIL"

export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST"
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS"
export const SEND_OTP_FAIL = "SEND_OTP_FAIL"
export const SEND_OTP_RESET = "SEND_OTP_RESET"

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST"
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS"
export const LOAD_USER_FAIL = "LOAD_USER_FAIL"


export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAIL = "LOGOUT_FAIL"


export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET"
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL"

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL"
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET"


export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET"

// here
export const ALL_USER_REQUEST = "ALL_USERS_REQUEST"
export const ALL_USER_SUCCESS = "ALL_USERS_SUCCESS"
export const ALL_USER_FAIL = "ALL_USERS_FAIL"


export const ALL_USER_FOR_ASSIGN_REQUEST = "ALL_USER_FOR_ASSIGN_REQUEST"
export const ALL_USER_FOR_ASSIGN_SUCCESS = "ALL_USER_FOR_ASSIGN_SUCCESS"
export const ALL_USER_FOR_ASSIGN_FAIL = "ALL_USER_FOR_ASSIGN_FAIL"

export const USER_DETAILS_REQUEST = "USERS_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USERS_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USERS_DETAILS_FAIL"


export const DELETE_USER_REQUEST = "DELETE_USERS_REQUEST"
export const DELETE_USER_SUCCESS = "DELETE_USERS_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USERS_FAIL"
export const DELETE_USER_RESET = "DELETE_USERS_RESET"


export const UPDATE_USER_REQUEST = "UPDATE_USERS_REQUEST"
export const UPDATE_USER_SUCCESS = "UPDATE_USERS_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USERS_FAIL"
export const UPDATE_USER_RESET = "UPDATE_USERS_RESET"

export const ACCEPT_TERM_REQUEST = "ACCEPT_TERM_REQUEST"
export const ACCEPT_TERM_SUCCESS = "ACCEPT_TERM_SUCCESS"
export const ACCEPT_TERM_FAIL = "ACCEPT_TERM_FAIL"
export const ACCEPT_TERM_RESET = "ACCEPT_TERM_RESET"


export const LOGIN_PASSWORD_REQUEST = "LOGIN_PASSWORD_REQUEST"
export const LOGIN_PASSWORD_SUCCESS = "LOGIN_PASSWORD_SUCCESS"
export const LOGIN_PASSWORD_FAIL = "LOGIN_PASSWORD_FAIL"
export const LOGIN_PASSWORD_RESET = "LOGIN_PASSWORD_RESET"

export const CLEAR_ERROR = "CLEAR_ERROR"

