export const CREATE_RESOURCE_REQUEST = "CREATE_RESOURCE_REQUEST"
export const CREATE_RESOURCE_SUCCESS = "CREATE_RESOURCE_SUCCESS"
export const CREATE_RESOURCE_FAIL = "CREATE_RESOURCE_FAIL"
export const CREATE_RESOURCE_RESET = "CREATE_RESOURCE_RESET"

export const UPDATE_RESOURCE_REQUEST = "UPDATE_RESOURCE_REQUEST"
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS"
export const UPDATE_RESOURCE_FAIL = "UPDATE_RESOURCE_FAIL"
export const UPDATE_RESOURCE_RESET = "UPDATE_RESOURCE_RESET"

export const DELETE_RESOURCE_REQUEST = "DELETE_RESOURCE_REQUEST"
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS"
export const DELETE_RESOURCE_FAIL = "DELETE_RESOURCE_FAIL"
export const DELETE_RESOURCE_RESET = "DELETE_RESOURCE_RESET"

export const GET_RESOURCE_REQUEST = "GET_RESOURCE_REQUEST"
export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS"
export const GET_RESOURCE_FAIL = "GET_RESOURCE_FAIL"

export const GET_ALL_RESOURCE_REQUEST = "GET_ALL_RESOURCE_REQUEST"
export const GET_ALL_RESOURCE_SUCCESS = "GET_ALL_RESOURCE_SUCCESS"
export const GET_ALL_RESOURCE_FAIL = "GET_ALL_RESOURCE_FAIL"

export const GET_ALL_MY_RESOURCE_REQUEST = "GET_ALL_MY_RESOURCE_REQUEST"
export const GET_ALL_MY_RESOURCE_SUCCESS = "GET_ALL_MY_RESOURCE_SUCCESS"
export const GET_ALL_MY_RESOURCE_FAIL = "GET_ALL_MY_RESOURCE_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"