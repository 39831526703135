import React, { useState } from 'react';
import Select from 'react-select';

const RoleSelect = ({roles, setRoles}) => {

  const rolesOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'agent', label: 'Agent' },
    { value: 'counsellor', label: 'Counsellor' },
    { value: 'supervisor', label: 'Supervisor' },
    { value: 'processor', label: 'Processor' },
  ];

  const handleChange = (selectedOptions) => {
    setRoles(selectedOptions);
  };

  return (
    <div className='updateInputItem'>
      <p>Select Roles</p>
      <Select
        isMulti
        options={rolesOptions}
        value={roles}
        onChange={handleChange}
        placeholder="Select roles..."
      />
      <div>
        {/* <h4>Selected Roles:</h4>
        <ul>
          {roles.map((role) => (
            <li key={role.value}>{role.label}</li>
          ))}
        </ul> */}
      </div>
    </div>
  );
};

export default RoleSelect;
