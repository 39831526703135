import React, { Fragment, useEffect, useState } from "react";
import { FaPhone, FaStarOfLife } from "react-icons/fa";
import { MdEmail, MdLock, MdPassword } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendOtpAction } from "../../actions/fileAction";
import { clearError, login, loginPasswordAction } from '../../actions/userAction';
import LoginLoading from '../../components/Loading/LoginLoading.js';
import MetaData from "../../components/MetaData";
import { SEND_OTP_RESET } from "../../constants/userConstant";
import "./login.css";

const Login = () => {

  // const redirect = location.search ? location.search.split("=")[1] : "/account";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { error, loading, isAuthenticated } = useSelector((state) => state.users)


  const [loginEmail, setloginEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [isType, setIsType] = useState('email');
  const [loginPassword, setloginPassword] = useState();
  const [resendOtp, setResendOtp] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  useEffect(() => {
    // console.log(error)
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }

    if (loading === false) {
      if (isAuthenticated) {
        // alert('step1')
        navigate('/dashboard')
        // toast.error(" login")
      }
    }

  }, [error, isAuthenticated]);


  const loginHandler = (e) => {
    e.preventDefault();
    // console.log({ email: loginEmail, phone, isType, otp })
    dispatch(login({ email: loginEmail, phone, isType, otp }))
  };

  // ============================================otp code ========================================

  const { error: otpError, user, loading: otpLoading, success } = useSelector((state) => state.sendOtp)

  // console.log("send otp : ", user)
  useEffect(() => {
    // console.log(error)
    if (otpError) {
      toast.error(otpError)
      dispatch(clearError())
    }

    if (success) {
      setResendOtp(!resendOtp)
      if (user?.role !== 'agent') {
        toast.success("OTP Send Successful!")
      }
      dispatch({ type: SEND_OTP_RESET })
    }

  }, [otpError, success]);

  const sendOtpHandler = (e) => {
    e.preventDefault();

    if (isType === 'phone') {
      if (phone.length > 10 || phone.length < 10) {
        toast.error('Phone number should be 10 digit.')
        return;
      }
    }


    dispatch(sendOtpAction({ email: loginEmail, phone, isType }))
  };

  const isTypeHandle = (e) => {
    e.preventDefault()

    if (isType === 'email') {
      setIsType('phone')
      return;
    }
    setIsType('email')
  }

  const loginWithPasswordHandler = (e) => {
    e.preventDefault()

    if (!user?.isPass && password !== confirmPassword) {
      toast.warn("Password and Confirm Password not matched!")
    } else {
      dispatch(loginPasswordAction({ email: user?.email, password }))
    }

  }
  return (
    <Fragment>
      {
        loading || otpLoading ? (<LoginLoading />) : (
          <div className="loginContainer">
            <MetaData title={'Login'} />
            <div className="imgBox">
              <img src={require('../../images/gleam-login.png')} alt="Gleam" />
            </div>
            <div>
              <div className="formBox">

                {
                  resendOtp ?
                    <>
                      {
                        (user?.role === 'agent') ?
                          <>
                            <h2>Log in - Last Step</h2>
                            <form action="" onSubmit={loginWithPasswordHandler}>
                              <div>
                                <MdEmail />
                                <input type="email" className="logInpBtn" placeholder="Email" value={user?.email} disabled={true} />
                              </div>
                              <div>
                                <MdLock />
                                <input type="text" className="logInpBtn" placeholder="Password" required value={password} onChange={(e) => setPassword(e.target.value)} />
                              </div>
                              {
                                !user?.isPass &&
                                <div>
                                  <MdLock />
                                  <input type="text" className="logInpBtn" required={!user?.isPass} placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                </div>
                              }

                              <div>
                                <input type="submit" className="submintBtn" value={"Submit"} />
                              </div>
                            </form>



                            <div className="regBox">
                              {/* <button onClick={e => setResendOtp(!resendOtp)}> Resend OTP ?</button> */}
                              {
                                user?.isPass &&
                                <Link to={'/forget-password'}>Forget Password ?</Link>
                              }
                            </div>
                          </>
                          :
                          // {/* otp login--------------------------------------------------------- */ }
                          <>
                            <h2>Enter OTP</h2>
                            <form action="" onSubmit={loginHandler}>
                              <div>
                                <FaStarOfLife />
                                <input type="number" className="logInpBtn" placeholder="OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                              </div>

                              <div>
                                <input type="submit" className="submintBtn" value={"Submit"} />
                              </div>
                            </form>

                            <div className="regBox">
                              <button onClick={e => setResendOtp(!resendOtp)}> Resend OTP ?</button>
                            </div>
                          </>
                      }

                    </>
                    :
                    <>
                      <h2>Login </h2>

                      <form action="" onSubmit={sendOtpHandler}>
                        {
                          isType === 'phone' ?
                            <div>
                              <FaPhone />
                              <input type="number" className="logInpBtn" placeholder="Phone no" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            :
                            <div>
                              <MdEmail />
                              <input type="email" className="logInpBtn" placeholder="Email" value={loginEmail} onChange={(e) => setloginEmail(e.target.value)} />
                            </div>


                        }



                        {/* <div>
                          <FiLock />
                          <input
                            type="password"
                            className="logInpBtn"
                            placeholder="password"
                            value={loginPassword} onChange={(e) => setloginPassword(e.target.value)}
                          />
                        </div> */}
                        <div>
                          <input type="submit" className="submintBtn" placeholder="name" value={"Submit"} />
                        </div>
                      </form>

                      {/* <div className="regBox">
                        <button onClick={isTypeHandle}>{isType === 'email' ? 'Send OTP on Phone ?' : 'Send OTP on Email ?'}</button>
                      </div> */}
                    </>
                }




                {/* <div className="regBox">
                  <Link to={"/forget/password"}>Forget Password ?</Link>
                </div> */}
              </div>
            </div>
          </div>
        )
      }
    </Fragment >
  );
};

export default Login;
