import axios from "axios";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_FAIL,
  LOAD_USER_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  ALL_USER_FAIL,
  ALL_USER_REQUEST,
  ALL_USER_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
  CLEAR_ERROR,
  INVITE_USER_REQUEST,
  INVITE_USER_FAIL,
  INVITE_USER_SUCCESS,
  ALL_USER_FOR_ASSIGN_REQUEST,
  ALL_USER_FOR_ASSIGN_SUCCESS,
  ALL_USER_FOR_ASSIGN_FAIL,
  INSTALL_MAIL_REQUEST,
  INSTALL_MAIL_SUCCESS,
  INSTALL_MAIL_FAIL,
  ACCEPT_TERM_REQUEST,
  ACCEPT_TERM_FAIL,
  ACCEPT_TERM_SUCCESS,
  LOGIN_PASSWORD_REQUEST,
  LOGIN_PASSWORD_SUCCESS,
  LOGIN_PASSWORD_FAIL,
} from "../constants/userConstant";
import host from "../utils/var";



//Login
export const login = (details) => async (dispatch) => {
  // console.log(details)
  try {
    dispatch({ type: LOGIN_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    const data = await axios.post(`${host}/api/v1/login`, details, config);
    // console.log(data.data.user )
    dispatch({ type: LOGIN_SUCCESS, payload: data.data.user });
  } catch (error) {
    // console.log(error?.response?.data.message)
    dispatch({ type: LOGIN_FAIL, payload: error?.response?.data.message });
  }
};

//Login
export const installMail = (id) => async (dispatch) => {
  // console.log(details)
  try {
    dispatch({ type: INSTALL_MAIL_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    const data = await axios.post(`${host}/api/v1/install-mail/${id}`, {}, config);
    // console.log(data.data.user )
    dispatch({ type: INSTALL_MAIL_SUCCESS, payload: data.data.user });
  } catch (error) {
    // console.log(error?.response?.data.message)
    dispatch({ type: INSTALL_MAIL_FAIL, payload: error?.response?.data.message });
  }
};

//register
export const register = (useData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    const data = await axios.post(`${host}/api/v1/register`, useData, config);
    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

//create user by admin / other
export const createUser = (useData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    const data = await axios.post(`${host}/api/v1/create/user`, useData, config);
    dispatch({ type: CREATE_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

//create user by admin / other
export const inviteUser = (useData, token) => async (dispatch) => {
  try {
    dispatch({ type: INVITE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    const data = await axios.post(`${host}/api/v1/invite/user/${token}`, useData, config);
    dispatch({ type: INVITE_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: INVITE_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// load user
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };


    const data = await axios.get(`${host}/api/v1/me`, config);
    dispatch({ type: LOAD_USER_SUCCESS, payload: data.data.user });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: LOAD_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// logout user
export const logout = () => async (dispatch) => {
  try {
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    await axios.get(`${host}/api/v1/loggout`, config);
    dispatch({ type: LOGOUT_SUCCESS });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: LOGOUT_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

//Update profile
export const updateProfile = (useData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" }, withCredentials: true };

    // console.log("6+...",)
    const data = await axios.put(`${host}/api/v1/profile/update`, useData, config);

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

//Update Password
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    console.log("7+...");
    const data = await axios.put(`${host}/api/v1/password/update`, passwords, config);

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

//Forget Password
export const forgetPassword = (email) => async (dispatch) => {
  try {
    const datas = { email }
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const data = await axios.post(`${host}/api/v1/password/forget`, datas, config);

    // console.log(data.data)

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.data.massage });
    // console.log(data.data.message )
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

export const resetPassword = (token, passwords) => async (dispatch) => {
  // console.log(token);
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };
    const data = await axios.put(
      `${host}/api/v1/password/reset/${token}`,
      passwords,
      config
    );
    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.data.success });
    // console.log(data.data.message )
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// get All  users
export const getAllUsers = (page) => async (dispatch) => {
  try {
    dispatch({ type: ALL_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const { data } = await axios.get(`${host}/api/v1/admin/users?page=${page}`, config);

    dispatch({
      type: ALL_USER_SUCCESS,
      payload: data,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: ALL_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// get All  users
export const getAllUsersForAssign = (page) => async (dispatch) => {
  try {
    dispatch({ type: ALL_USER_FOR_ASSIGN_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const { data } = await axios.get(`${host}/api/v1/admin/all-users`, config);

    dispatch({
      type: ALL_USER_FOR_ASSIGN_SUCCESS,
      payload: data,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: ALL_USER_FOR_ASSIGN_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// get   users details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };


    const { data } = await axios.get(`${host}/api/v1/admin/user/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data.user,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// accept-terms
export const acceptTermsAction = () => async (dispatch) => {

  try {
    dispatch({ type: ACCEPT_TERM_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const { data } = await axios.put(`${host}/api/v1/accept-terms`, {}, config);

    dispatch({
      type: ACCEPT_TERM_SUCCESS,
      payload: data.success,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: ACCEPT_TERM_FAIL,
      payload: error?.response?.data.message,
    });
  }
};
// accept-terms
export const loginPasswordAction = (details) => async (dispatch) => {

  try {
    dispatch({ type: LOGIN_PASSWORD_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const { data } = await axios.put(`${host}/api/v1/login-password`, details, config);

    dispatch({
      type: LOGIN_PASSWORD_SUCCESS,
      payload: data.user,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: LOGIN_PASSWORD_FAIL,
      payload: error?.response?.data.message,
    });
  }
};
// update user
export const updateUser = (id, userData) => async (dispatch) => {
  console.log(userData)
  try {
    dispatch({ type: UPDATE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const { data } = await axios.put(`${host}/api/v1/admin/user/${id}`, userData, config);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.success,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// delete user
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });
    const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

    const { data } = await axios.delete(`${host}/api/v1/admin/user/${id}`, config);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data.success,
    });
    // console.log(data.user)
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error?.response?.data.message,
    });
  }
};

// clearing error
export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERROR });
};
