import { CREATE_SCREEN_TRACKS_FAIL, CREATE_SCREEN_TRACKS_REQUEST, CREATE_SCREEN_TRACKS_RESET, CREATE_SCREEN_TRACKS_SUCCESS, GET_MONITOR_USERS_FAIL, GET_SCREEN_TRACK_FAIL, GET_SCREEN_TRACK_REQUEST, GET_SCREEN_TRACK_SUCCESS, GET_SCREEN_TRACKS_FAIL, GET_SCREEN_TRACKS_REQUEST, GET_SCREEN_TRACKS_SUCCESS } from '../constants/monitoringConstant.js';
import { GET_MONITOR_USER_REQUEST } from '../constants/monitoringConstant.js';
import { GET_MONITOR_USER_FAIL } from '../constants/monitoringConstant.js';
import { GET_RECORDINGS_REQUEST } from '../constants/monitoringConstant.js';
import { GET_LOGS_REQUEST } from '../constants/monitoringConstant.js';
import { GET_RECORDINGS_FAIL } from '../constants/monitoringConstant.js';
import { GET_LOGS_FAIL } from '../constants/monitoringConstant.js';
import { GET_RECORDINGS_SUCCESS } from '../constants/monitoringConstant.js';
import { CLEAR_ERROR } from '../constants/monitoringConstant.js';
import { GET_LOGS_SUCCESS } from '../constants/monitoringConstant.js';
import { GET_SCREENSHOTS_SUCCESS } from '../constants/monitoringConstant.js';
import { GET_APPS_SUCCESS } from '../constants/monitoringConstant.js';
import { GET_SCREENSHOTS_FAIL } from '../constants/monitoringConstant.js';
import { GET_APPS_FAIL } from '../constants/monitoringConstant.js';
import { GET_SCREENSHOTS_REQUEST } from '../constants/monitoringConstant.js';
import { GET_APPS_REQUEST } from '../constants/monitoringConstant.js';
import { GET_MONITOR_USER_SUCCESS } from '../constants/monitoringConstant.js';
import { GET_MONITOR_USERS_REQUEST } from '../constants/monitoringConstant.js';
import { GET_MONITOR_USERS_SUCCESS } from '../constants/monitoringConstant.js';




export const getMonitorUsers = (state = { users: [] }, action) => {
    switch (action.type) {
        case GET_MONITOR_USERS_REQUEST:
            return {
                loading: true,
            };

        case GET_MONITOR_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users,
                // resultPerPage: action.payload.resultPerPage,
                // totalUsers: action.payload.totalUsers
            };



        case GET_MONITOR_USERS_FAIL:
            return {
                ...state,
                loading: false,
                users: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getMonitorUser = (state = { student: {} }, action) => {
    switch (action.type) {
        case GET_MONITOR_USER_REQUEST:
            return {
                loading: true,
            };

        case GET_MONITOR_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user,
            };


        case GET_MONITOR_USER_FAIL:
            return {
                ...state,
                loading: false,
                students: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getMonitorApi = (state = { data: {} }, action) => {
    switch (action.type) {
        case GET_APPS_REQUEST:
        case GET_RECORDINGS_REQUEST:
        case GET_SCREENSHOTS_REQUEST:
        case GET_LOGS_REQUEST:
            return {
                loading: true,
            };

        case GET_APPS_SUCCESS:
            return {
                ...state,
                loading: false,
                apps: action.payload.apps,
                totalApps: action.payload.totalApps,
                resultPerPage: action.payload.resultPerPage
            };

        case GET_RECORDINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                recording: action.payload.recording,
                totalRecording: action.payload.totalRecording,
                resultPerPage: action.payload.resultPerPage
            };

        case GET_SCREENSHOTS_SUCCESS:
            return {
                ...state,
                loading: false,
                screenshots: action.payload.screenshots,
                totalScreenshots: action.payload.totalScreenshots,
                resultPerPage: action.payload.resultPerPage
            };

        case GET_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                logs: action.payload.logs,
                totalLogs: action.payload.totalLogs,
                resultPerPage: action.payload.resultPerPage
            };


        case GET_APPS_FAIL:
        case GET_RECORDINGS_FAIL:
        case GET_SCREENSHOTS_FAIL:
        case GET_LOGS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};


export const getTracks = (state = { data: [] }, action) => {
    switch (action.type) {
        case GET_SCREEN_TRACKS_REQUEST:
            return {
                loading: true,
            };

        case GET_SCREEN_TRACKS_SUCCESS:
            return {
                ...state,
                loading: false,
                tracks: action.payload.tracks,
                resultPerPage: action.payload.resultPerPage,
                totalTracks: action.payload.totalTracks
            };



        case GET_SCREEN_TRACKS_FAIL:
            return {
                ...state,
                loading: false,
                tracks: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};

export const getTrack = (state = { student: {} }, action) => {
    switch (action.type) {
        case GET_SCREEN_TRACK_REQUEST:
            return {
                loading: true,
            };

        case GET_SCREEN_TRACK_SUCCESS:
            return {
                ...state,
                loading: false,
                track: action.payload.track,
            };


        case GET_SCREEN_TRACK_FAIL:
            return {
                ...state,
                loading: false,
                track: null,
                error: action.payload,
            };

        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};


export const createTrack = (state = { student: {} }, action) => {
    switch (action.type) {
        case CREATE_SCREEN_TRACKS_REQUEST:
            return {
                loading: true,
            };

        case CREATE_SCREEN_TRACKS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };


        case CREATE_SCREEN_TRACKS_FAIL:
            return {
                ...state,
                loading: false,
                success: null,
                error: action.payload,
            };

        case CREATE_SCREEN_TRACKS_RESET:
            return {
                ...state,
                loading: false,
                success: null,
                error: null,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null,
            };

        default:
            return state;
    }
};