import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MetaData from "../../components/MetaData";
import Loading from "../../components/Loading/loading.js";
import "./profile.css";
// import DashboardHeader from "../../components/dashboardheader/DashboardHeader";
import DashboardHeader from "../Dashboard/DashboardHeader";

import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import { clearError, loadUser } from "../../actions/userAction";

const UpdateProfile = () => {
  const {error, user, loading } = useSelector((state) => state.users);
  // console.log(user)
  const dispatch = useDispatch();

  
  useEffect(() => {
    // dispatch(loadUser());

    if(error){
      toast.error(error)
      dispatch(clearError())
    }
  }, [error]);
  return (
    <Fragment>
      <div className="dashboardMain">
        <div className={window.innerWidth>600 ?"menuContainer show" :"menuContainer hide" }>
          <Sidebar />
        </div>
        <div className="dashboardContainer">
          <DashboardHeader heading="Profile" />

          <div className="ButtonLayer"></div>
          {loading ? (
        <Loading />
      ) : (
        <Fragment>
          <MetaData title={`${user?.name}`} />

          <div className="profileContainer">
            <div>
              <h1>MY PROFILE</h1>
              <img src={user?.avtar?.url} alt="profile" />
              <Link to=""></Link>
              {/* <div></div> */}
            </div>
            <div>
              <div className="profileContent">
                <h4>Full Name</h4>
                <p>{user?.name}</p>
              </div>
              <div>
                <h4>Email</h4>
                <p>{user?.email}</p>
              </div>
              <div>
                <h4>Role</h4>
                <p>{String(user?.role)}</p>
              </div>
              <div>
                <h4>Status</h4>
                <p>{user?.isActive ? "Active" : "Not Active" }</p>
              </div>
              <div>
                <h4>Phone</h4>
                <p>{String(user.phone ? user.phone :'Please update your phone no' )}</p>
              </div>
              <div>
                <h4>Joined On</h4>
                <p>{String(user?.createAt).substr(0, 10)}</p>
              </div>

              <div>
                <Link to="/update/profile">Edit Profile</Link>
                {/* <Link to="/change/password">Change Password</Link> */}
              </div>
            </div>
          </div>
        </Fragment>
      )}
        </div>
      </div>
     
    </Fragment>
  );
};

export default UpdateProfile;
