import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/loading";
import Sidebar from "../../components/Sidebar";
// import "./StudentUpdate.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DocumentUploadUi from '../../components/DocumentUploadUi.jsx';
import { STUDENT_UPDATE_RESET } from "../../constants/fileConstant";
import { clearError,createUniversityAction } from "../../actions/universityAction.js";
import { CREATE_UNIVERSITY_RESET } from "../../constants/universityConstant.js";

const StudentUpdate = () => {
    const dispatch = useDispatch();

    const { error, isUpdated, loading } = useSelector(
        (state) => state.universityUpdate
    );

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.users);


    const [fields, setFields] = useState([
        // { name: "", file: null, fileType: "" }
    ]);

    const [studentData, setStudentData] = useState({
        name: '', country: '', appSubmitLink: '', courseLink: ''
    })

    const {
        name, country, appSubmitLink, courseLink
    } = studentData;


    const studentCreateHandler = async (e) => {
        e.preventDefault();

        let data = {
            name, 
            country,
            appSubmitLink,
            courseLink,
        };

        // console.log(data)

        dispatch(createUniversityAction({bodyData:data, fields}));
    };



    const studentDataChangeHandler = e => {
        setStudentData({ ...studentData, [e.target.name]: e.target.value })
    }


    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
            // setSelectedMultiFile([])
        }
        if (isUpdated) {
            toast.success("University has created Successfully");
            // if(user?.role==='counsellor'){
            // }else{
            // }
            dispatch({ type: CREATE_UNIVERSITY_RESET });
            navigate(-1);
        }
    }, [error, isUpdated, dispatch]);

    return (
        <div className="dashboardMain">
            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentUpdateBox">
                        <form action="" onSubmit={studentCreateHandler}>
                            <h3>Create New University </h3>


                            <>
                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>University Name</p>
                                        <input
                                            type="text"
                                            required
                                            value={name}
                                            name="name" placeholder="value"
                                            onChange={studentDataChangeHandler} />
                                    </div>

                                </div>



                                <div className="updateItemBox">

                                    <div className="updateInputItem">
                                        <p>Country </p>
                                        <input
                                            type="text"
                                            value={country}
                                            name="country" placeholder="value"
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                </div>





                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Course Link</p>
                                        <input
                                            name="courseLink"
                                            type="text" placeholder="value"
                                            value={courseLink}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                </div>


                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Application Submit Link</p>
                                        <input
                                            name="appSubmitLink" placeholder="value"
                                            type="text"
                                            value={appSubmitLink}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                </div>




                                <br />


                            </>


                            <DocumentUploadUi setFields={setFields} fields={fields} title="Upload Files"/>



                            <div className="submitItem">
                                <input type="submit" value={"Create University"} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentUpdate;
