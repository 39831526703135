import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { FaClock, FaSearch, FaTimes } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getLogs } from "../../actions/monitoringAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import SidebarMonitor from "../../components/SidebarMonitor.js";
import AsignedHeader from "../../Pages/FileMangement/AsignedHeader";
import "./monitorActiveApps.css";
import Sidebar from "../../components/Sidebar.js";
import SelectMonitorUserUI from "./SelectMonitorUserUI.js";




const StudentsApplication = ({ underLine, setUnderLine }) => {
    setUnderLine('logs');



    const dispatch = useDispatch();
    const { error, logs, resultPerPage, totalLogs, loading } = useSelector((state) => state.getMonitorApi);
    const { user,isAuthenticated } = useSelector((state) => state.users);

    useEffect(() => {
        if (error) {
            console.log(error)
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);

    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);

    const formattedDate = today.toISOString().split('T')[0];
    const nextDate = nextDay.toISOString().split('T')[0];

    const [date1, setDate1] = useState(formattedDate);
    const [date2, setDate2] = useState(nextDate);
    const [sno, setSno] = useState('');
    const [id, setId] = useState('');
    const [CurrentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        searchHandler()
    }, [sno, id, CurrentPage]);

    useEffect(() => {
        try {

            // const sno = window.localStorage.getItem('sno');
            // const id = window.localStorage.getItem('id');

            // if (sno && id) {
            //     setSno(sno)
            //     setId(id)
            // }

            console.log(sno, id)

        } catch (error) {
            console.error(error);
        }

    }, [sno, id]);

    const Navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated)
            if (user?.role !== 'admin') {
                Navigate('/dashboard');
            }
    }, [user])

    const searchHandler = () => {

        dispatch(getLogs({ sno, id, date: date1, date2, page: CurrentPage }));

    }
    const searchByHandler = () => {
        setCurrentPage(1)


        dispatch(getLogs({ sno, id, date: date1, date2, page: CurrentPage }));


    }


    const columns = [
        { field: "id", headerName: "S No.", minWidth: 50, flex: 0.2 },


        {
            field: "status",
            headerName: "Status",
            minWidth: 50,
            flex: 0.3,
        },
        {
            field: "workingTime",
            headerName: "Working Time",
            minWidth: 50,
            flex: 0.3,
        },

        {
            field: "date",
            headerName: "Created At",
            minWidth: 200,
            flex: 0.3,
            // cellClassName: (params) => {
            //   return (params.getValue(params.row.id_, "role"))
            // },
        },
        // {
        //     field: "actions",
        //     headerName: "Actions",
        //     type: "number",
        //     minWidth: 220,
        //     flex: 0.3,
        //     sortable: false,
        //     renderCell: (params) => {
        //         console.log("dsf", params.id);
        //         return (
        //             <div key={params.id} >

        //                 {/* <Link to={`/monitoring/${params.row.id_}`} className="student-icons">
        //                     <AiFillEye />
        //                 </Link> */}


        //             </div>
        //         );
        //     },
        // },
    ];

    const rows = [];

    logs &&
        logs.forEach((item, i) => {
            let date = new Date(item?.date)

            rows.push({
                id: ((CurrentPage - 1) * resultPerPage) + i + 1,
                id_: item._id,
                status: item.status,
                workingTime: item.workingTime,
                date: date.toString().split('G')[0]
            });
        });

    // modal code 


    return (
        <div className="dashboardMain">
            <MetaData title={"Monitoring"} />

            <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
                <SidebarMonitor underLine={underLine} />

            </div>


            <div className="dashboardContainer">
                <AsignedHeader heading={"Clock in out"} />
                <SelectMonitorUserUI setId={setId} setSno={setSno} />
                {
                    loading ? <Loading /> : (
                        <>
                            <div className="D-ButtonLayer">

                                <div className="BlRow1">
                                    <div className="BlItem BlItem4">
                                        <div>
                                            <p>Log Activity</p>
                                            <p>{totalLogs}</p>
                                        </div>
                                        <div className="">
                                            <FaClock />
                                        </div>
                                    </div>

                                    <div className="student-date-box">
                                        <p style={{ paddingBottom: '7px' }}>Both Date Required <b style={{ color: 'red' }}>*</b></p>

                                        <form action="" onSubmit={searchByHandler}>
                                            <input type="date" placeholder="Select Date" className="docBtn1" value={date1} onChange={e => setDate1(e.target.value)} />
                                            to
                                            <input type="date" placeholder="Select Date" className="docBtn1" value={date2} onChange={e => setDate2(e.target.value)} />

                                            <button type="submit" className="docBtn1" onClick={searchByHandler} value={'find'} ><FaSearch /></button>
                                        </form>
                                    </div>
                                </div>

                            </div>

                            <div className="usersContainer">
                                {
                                    rows.length !== 0 ? (<DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={20}
                                        disableSelectionOnClick
                                        className="usersTable"
                                        // autoHeight
                                        autoWidth
                                        autoHeight

                                        sx={{
                                            fontSize: 14,
                                            backgroundColor: 'white',
                                            boxShadow: 0,
                                            fontFamily: 'Roboto',
                                            color: 'black',
                                            //   headerColor: 'black',
                                            padding: 2,
                                            '& .MuiDataGrid-cell:hover': {
                                                color: 'primary.main',
                                            },

                                        }}
                                    />) : <NoDataFound />
                                }


                            </div>

                            {
                                totalLogs > resultPerPage &&

                                <div className="paginationContainer">
                                    <Pagination
                                        activePage={CurrentPage}
                                        itemsCountPerPage={resultPerPage}
                                        totalItemsCount={totalLogs}
                                        onChange={e => setCurrentPage(e)}

                                        className="paginationBox"

                                        // prevPageText="<"
                                        firstPageText="1st"
                                        lastPageText="Last"
                                        // nextPageText=">"
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='pageItemActive'
                                        activeLinkClass='pageLinkActive'
                                    />
                                </div>
                            }

                        </>
                    )
                }
            </div>


        </div>
    );
};

export default StudentsApplication;
