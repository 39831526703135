import "./DashboardHeader.css";
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { BiMenu } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { logout } from '../../actions/userAction';
import { useDispatch, useSelector } from "react-redux";
import { RiLogoutBoxLine } from "react-icons/ri";


const Header = ({ heading }) => {
  const {error, user } = useSelector((state) => state.users);
  let URL = ''
  if(user?.avtar !== undefined){
    // console.log(user.avtar)
     URL = user?.avtar.url

  }

  // console.log()
  window.scrollTo(0, 0);
  const [isHide, setisHide] = useState(true);

  const navigate=useNavigate()
  const dispatch = useDispatch();

  const menuHandler = () => {
    console.log("true");

    if (isHide) {
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("hide");
      document.getElementsByClassName("menuContainer")[0].classList.add("show");
      //   isHide = false
      setisHide(false);
    } else {
      document.getElementsByClassName("menuContainer")[0].classList.add("hide");
      document
        .getElementsByClassName("menuContainer")[0]
        .classList.remove("show");

      // isHide = true
      setisHide(true);
    }
  };

  const logOutHandler = ()=>{
    dispatch(logout())
    // navigate("/")
    document.cookie = 'token' + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    window.location.href = '/'

}
const [isOpen, setIsOpen] = useState(false)


  const downNavMenu = document.getElementsByClassName("downNavMenu");
  const handleMenu = () => {
    if (isOpen) {
      downNavMenu[0].classList.remove("downNavHide");
    } else {
      downNavMenu[0].classList.add("downNavHide");
    }
    setIsOpen(!isOpen)
  };
  const mouseEnterMenu = (num) => {
    downNavMenu[num].classList.remove("downNavHide");
  };
  const mouseLeaveMenu = (num) => {
    downNavMenu[num].classList.add("downNavHide");
  };
  return (
    <div className="HeaderContainer">
      <div className="HeaderBox">
        <div className="linkBox">
          <h1>Welcome {user?.name} !</h1>
        </div>
        <div>
        <Link to={'/file/upload'}  className="docBtn">Bulk  Applications</Link>

          <div
          onClick={handleMenu}
            className="profile-Box"
          >
          {
              URL !== '' ? <img src={URL} alt=""  height={'100%'} width={'100%'} /> :  <FaUser/>
            }

            <div className="downNavMenu downNavHide ">
              <Link to={'/profile'}>
              <FaUser style={{color:'rgb(255, 204, 0)'}}/>
                Profile
              </Link>
              <Link to={''} onClick={()=>logOutHandler()} >
              <RiLogoutBoxLine style={{color:'tomato'}}/>
                Log Out
              </Link>
            </div>
          </div>
          <div onClick={(e) => menuHandler()} className="menu">
            <BiMenu />
          </div>

        </div>
      </div>
      <h3 className="headerHeading">{heading}</h3>

    </div>
  );
};

export default Header;
