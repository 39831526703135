// import "./UpdateProfile.css";

import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  clearError,
  updatePassword,
} from "../../actions/userAction";

import { toast } from "react-toastify";
import { MdEmail, MdVerifiedUser } from "react-icons/md";
import { FiLock } from "react-icons/fi";
import { FaLock, FaLockOpen, FaPhone, FaPhotoVideo, FaUser, FaVoicemail } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import Loading from "../../components/Loading/loading";

const CreateUser = () => {

  const { error:updateError, isUpdated, loading } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
  
    if (updateError) {
      toast.error(updateError);
      dispatch(clearError())
    }
    if(isUpdated){
      toast.success('Password Change Sucessfull.')
      navigate('/profile')
    }
  }, [updateError,isUpdated]);



  const [userPassword, setUserPassword] = useState({
    oldPassword: '',
    newPassword:'',
    confirmPassword:'',
  
  });
  const {newPassword, confirmPassword, oldPassword } = userPassword;
  
    const passwordChangeSubmit = (e)=>{
      e.preventDefault();
      
      const myForm = new FormData();
       myForm.set("oldPassword",oldPassword)
       myForm.set("newPassword",newPassword)
       myForm.set("confirmPassword",confirmPassword)

         dispatch(updatePassword(myForm));
      }
      const updateDataChange = (e)=>{
        setUserPassword({...userPassword , [e.target.name]: e.target.value})
    };   

  return (
    <div className="dashboardMain">
      <div className={window.innerWidth>600 ?"menuContainer show" :"menuContainer hide" }>
        <Sidebar />
      </div>
     {
      loading ? <Loading/> : (
        <div className="dashboardContainer">
        <DashboardHeader heading="Update Profile..." />
        <div className="createUserContainer">
          <form action="" onSubmit={(e) => passwordChangeSubmit(e)}>
            <div>
              <FaLockOpen />
              <input
                type="number"
                required
                className="inpBtn"
                name="oldPassword"
                placeholder="Old Password"
                value={oldPassword}
                onChange={updateDataChange}
              />
            </div>
            <div>
            <FaLock />

              <input
                type="password"
                required
                className="inpBtn"
                name="newPassword"
                placeholder="New Password"
                value={newPassword}
                onChange={updateDataChange}
              />
            </div>
            <div>
            <FaLock />

              <input
                type="password"
                placeholder="Confirm Password"
                name="confirmPassword"
                value={confirmPassword}
                required
                className="inpBtn"
                onChange={updateDataChange}
              />
            </div>

           
            <div>
              <input
                type="submit"
                className="submintBtn"
                value={"Update Profile "}
                placeholder="name"
              />
            </div>
          </form>
        </div>
      </div>
      )
     }
    </div>
  );
};

export default CreateUser;
