import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createTrackAction } from '../actions/monitoringAction';
import { acceptTermsAction, clearError } from '../actions/userAction';
import { ACCEPT_TERM_RESET } from '../constants/userConstant';
import './acceptTermsUi.css';
import Loading from './Loading/loading';

function AcceptTermsUi() {
    const { user, isAuthenticated } = useSelector(state => state.users)
    const { error, isUpdated, loading } = useSelector((state) => state.profile);

    const dispatch = useDispatch();

    useEffect(() => {

        if (error) {
            toast.error(error);
            dispatch(clearError())
        }
        if (isUpdated) {
            toast.success('Terms and conditions Accepted.')
            window.location.reload(true);
            dispatch({ type: ACCEPT_TERM_RESET })
        }
    }, [error, isUpdated, isAuthenticated]);

    const acceptTermsHandle = () => {
        dispatch(acceptTermsAction())
    }

    // console.log(, user?.)

    // ----------------------------------------------------------------------screenshot ------------------------------------------------------
   

    const timeoutRef = useRef(null);


    const createTrackHandle = (status) => {
        if (user?._id) dispatch(createTrackAction({ userId: user?._id, title: document.title, url: window.location.href, status }))
        // console.log({ userId:user?._id, title: document.title, url: window.location.href, status })
    }
    const handleClick = (status) => {
        debouncedFetchData(status);
    };

    // const debouncedFetchData = useCallback(throttle(createTrackHandle, 1000), []);

    // Function to handle debounced API call using setTimeout
    const debouncedFetchData = (status) => {
        // Clear any existing timeout
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a new timeout to delay the API call
        timeoutRef.current = setTimeout(() => {
            createTrackHandle(status);
        }, 1000); // Delay of 1000ms (1 second)
    };

    useEffect(() => {

        const preventScreenshot = (event) => {
            // console.log(event);

            // Block PrtSc key
            if (event.key === "PrintScreen") {
                event.preventDefault();
                handleClick("Screenshot")
                console.log('screenshot 1');
            }



            // Block Ctrl + S (screenshot on some systems)
            if (event.ctrlKey && event.key === "s") {
                event.preventDefault();
                handleClick("Save page")

                console.log("Save page");

            }

            // Block Cmd + Shift + 4 on macOS
            if (event.metaKey && event.shiftKey && event.key === "4") {
                event.preventDefault();
                handleClick("Screenshot MacOS")

                console.log('Screenshot MacOS')
            }
        };
        window.addEventListener("keyup", preventScreenshot);

    }, []);




    return (
        <>
            {
                (isAuthenticated && !user?.acceptTerms) ?

                    <>
                        {
                            loading ? <Loading /> :
                                <div className='modal-Container'>

                                    <div className="modal-Box">
                                        <div>
                                            <h4>Data privacy and protection guidelines</h4>
                                            <br />
                                            In today’s world Data is a very valuable asset for any organization. Data which an
                                            organization possesses could be anything like personal data of the clients, financial
                                            details, confidential data, in-house data generated during the course of business
                                            activity, trade secrets, software’s, portal access, email access and their data etc.
                                            In India, Cyber laws are majorly governed by the Information Technology Act,
                                            2000 (hereinafter referred to as the ‘IT Act’) and Rules framed there under.
                                            Section 2(o) of the IT Act, 2000 defines the word “Data” in following words:
                                            <br />
                                            "Data" means a representation of information, knowledge, facts, concepts or
                                            instructions which are being prepared or have been prepared in a formalised
                                            manner, and is intended to be processed, is being processed or has been processed
                                            in a computer system or computer network, and may be in any form (including
                                            computer printouts magnetic or optical storage media, punched cards, punched
                                            tapes) or stored internally in the memory of the computer;
                                            <br />
                                            ‘Information Technology (Reasonable security practices and procedures and
                                            sensitive personal data or information) Rules, 2011’ (hereinafter referred to as the
                                            IT Rules, 2011) which protects ‘Sensitive personal data’.

                                            <br />
                                            <h4>DATATHEFT</h4>

                                            ‘DATA THEFT’ in simple terms means an act of illegal/ unauthorized copying,
                                            removal or stealing of confidential, valuable or personal data/ information from an
                                            organization or business without its knowledge or consent. Data theft could be with
                                            respect to stealing or hacking passwords, student information, personal information
                                            of clients/ other employees, information of importance to a body corporate like
                                            client database, associate data, University data softwares, source codes,
                                            confidential information, information which the body corporate is bound to protect,
                                            hacking into databases and many more in line with these.
                                            <br />
                                            Employees are undoubtedly the biggest asset for any organization. However, if
                                            employees are negligent about following the security measures set up to protect the
                                            company’s data or if they themselves do something with an intent to compromise
                                            someone’s privacy or to obtain confidential information, they could become its
                                            liability.
                                            <br />
                                            Such an act by an employee casts liability not only on the offender employee but
                                            also on the body corporate which possesses or deals with any such sensitive
                                            personal data or information.
                                            <br />
                                            Further, Section 72A provides for the punishment for disclosure of information in
                                            breach of lawful contract and any person may be punished with imprisonment for a
                                            term not exceeding three years, or with a fine not exceeding up to five lakh rupees,
                                            or with both in case disclosure of information is made in breach of lawful contract.
                                            <br />
                                            I have read and understood all the policy of Gleam Education Services by signing
                                            this document I confirm that I will abide by the policies.
                                            <br />
                                            <br />

                                            {/* Employee signature: 

                                            <br />

                                            Employee Name:

                                            <br />


                                            Designation:
                                            <br />

                                            Date: */}
                                        </div>
                                        <div className='acceptedBox'>
                                            <button className='docBtn' onClick={acceptTermsHandle}>Accepted</button>
                                        </div>
                                    </div>

                                </div>
                        }
                    </>
                    : ''
            }
        </>
    )
}

export default AcceptTermsUi