import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import MetaData from "../../components/MetaData";
import Loading from "../../components/Loading/loading.js";
import "./monitorPage.css";
import DashboardHeader from "../Dashboard/DashboardHeader";

import SidebarMonitor from "../../components/SidebarMonitor.js";
import { toast } from "react-toastify";
import { clearError, getMonitorUser } from "../../actions/monitoringAction.js";

const MonitorPage = ({ underLine, setUnderLine }) => {

    setUnderLine('monitoring')

    const { error, user, loading } = useSelector((state) => state.getMonitorUser);
    // console.log(user)
    const dispatch = useDispatch();
    const Navigate = useNavigate();


    const { id } = useParams();

    useEffect(() => {
        dispatch(getMonitorUser(id))
    }, [id])






    useEffect(() => {
        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);


    const selectDeviceIdHandle = (sno) => {

        window.localStorage.setItem('sno', sno);
        window.localStorage.setItem('id', id);

        Navigate('/active-app')
    }
    return (
        <Fragment>
            <div className="dashboardMain">
                <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
                    < SidebarMonitor />
                </div>
                <div className="dashboardContainer">
                    <DashboardHeader heading="User Profile" />

                    <div className="ButtonLayer"></div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <Fragment>
                            <MetaData title={`${user?.name}`} />

                            <div className="profileContainer">
                                <div>
                                    <h3>User Details</h3>
                                    <br />
                                    <div className="profileContent">
                                        <h4>Full Name</h4>
                                        <p>{user?.name}</p>
                                    </div>
                                    <div>
                                        <h4>Email</h4>
                                        <p>{user?.email}</p>
                                    </div>
                                    <div>
                                        <h4>Role</h4>
                                        <p>{String(user?.role)}</p>
                                    </div>
                                    <div>
                                        <h4>Status</h4>
                                        <p>{user?.isActive ? "Active" : "Not Active"}</p>
                                    </div>


                                    <br />
                                    <h3>Devices</h3>
                                    <br />

                                    {
                                        user && user?.monitorDevices.map((item, i) => (
                                            <div className="deviceItem">
                                                <div>
                                                    <img src={require('../../images/windows.webp')} width={100} alt="Windows" />
                                                </div>

                                                <div>
                                                    <h4>{item?.manufacturer}</h4>
                                                    <p><b>Device Serial Number</b> : {item?.serialNumber}</p>
                                                    <p><b>Platform</b> : {item?.platform}</p>
                                                    <p><b>Architecture</b> : {item?.architecture}</p>
                                                    <p><b>CPU Core</b> : {item?.cpuCores}</p>
                                                    <p><b>Total Memory</b> : {Math.round((item?.totalMemory) / 1024 / 1024 / 1024)} GB</p>
                                                    {/* <p><b>Free Memory</b> : {(item?.freeMemory) / 1024 / 1024 / 1024}</p> */}
                                                    <p><b>Hostname</b> : {item?.hostname}</p>
                                                    <p><b>Version</b> : {item?.version}</p>
                                                    <br />
                                                    {/* <button className="selectBtn" onClick={e => { selectDeviceIdHandle(item.serialNumber) }} >Select</button> */}
                                                </div>


                                            </div>


                                        ))
                                    }






                                    <div>
                                        {/* <Link to="/update/profile">Edit Profile</Link> */}
                                        {/* <Link to="/change/password">Change Password</Link> */}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>

        </Fragment>
    );
};

export default MonitorPage;
