import axios from "axios";
import { CLEAR_ERROR, CREATE_COMMENT_FAIL, CREATE_COMMENT_REQUEST, CREATE_COMMENT_SUCCESS, DELETE_COMMENT_FAIL, DELETE_COMMENT_REQUEST, DELETE_COMMENT_SUCCESS, GET_COMMENTS_FAIL, GET_COMMENTS_REQUEST, GET_COMMENTS_SUCCESS, UPDATE_COMMENT_FAIL, UPDATE_COMMENT_REQUEST, UPDATE_COMMENT_SUCCESS } from '../constants/commentConstant'

const commentHost = "https://comments.submitapp.in";
// const commentHost = "http://localhost:5159";



export const createCommentAction = (details) => async (dispatch) => {

    try {

        dispatch({ type: CREATE_COMMENT_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${commentHost}/api/v1/create-comment`, details, config);

        dispatch({ type: CREATE_COMMENT_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: CREATE_COMMENT_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const updateCommentAction = (_id,details) => async (dispatch) => {

    try {

        dispatch({ type: UPDATE_COMMENT_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.put(`${commentHost}/api/v1/update-comment/${_id}`, details, config);

        dispatch({ type: UPDATE_COMMENT_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: UPDATE_COMMENT_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const deleteCommentAction = (_id) => async (dispatch) => {

    try {

        dispatch({ type: DELETE_COMMENT_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.put(`${commentHost}/api/v1/delete-comment/${_id}`, {}, config);

        dispatch({ type: DELETE_COMMENT_SUCCESS, payload: data.data });

    } catch (error) {

        dispatch({
            type: DELETE_COMMENT_FAIL,
            payload: error?.response?.data.message,
        });
    }
};



export const getCommentsAction = (studentId,page,commentType) => async (dispatch) => {

    try {

        dispatch({ type: GET_COMMENTS_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${commentHost}/api/v1/get-comments/${studentId}?page=${page}&commentType=${commentType}`, config);

        dispatch({ type: GET_COMMENTS_SUCCESS, payload: data.data });
    } catch (error) {

        dispatch({
            type: GET_COMMENTS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

