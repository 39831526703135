// import "./UpdateProfile.css";

import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { clearError, resetPassword } from "../../actions/userAction";

import { toast } from "react-toastify";
import { FaLock } from "react-icons/fa";
import Loading from "../../components/Loading/loading";
import { RESET_PASSWORD_RESET } from "../../constants/userConstant";

const InviteForgetPassword = () => {
  const { error, success, loading } = useSelector(
    (state) => state.forgetPassword
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (success) {
      toast.success("Password has Changed Successful. Please Login");
      navigate('/')
      dispatch({ type: RESET_PASSWORD_RESET })
    }
  }, [error, success]);

  const [userPassword, setUserPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = userPassword;

  const passwordResetSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();
    myForm.set("password", password);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(resetPassword(token, myForm));
  };
  const updateDataChange = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
  };

  const registerDataChange = (e) => {
    setUserPassword({ ...userPassword, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      {
        loading ? <Loading /> : (<div className="dashboardMain">
          <div className="loginContainer">
            <div className="imgBox">
              <img src={require("../../images/signup.webp")} alt="image" />
            </div>
            <div>
              <div className="formBox">
                <h2>Reset your password</h2>
                <form action="" onSubmit={(e) => passwordResetSubmit(e)}>
                  <div>
                    <FaLock />
                    <input
                      type="password"
                      className="logInpBtn"
                      placeholder="Password"
                      value={password}
                      onChange={registerDataChange}
                      name="password"
                    />
                  </div>
                  <div>
                    <FaLock />
                    <input
                      type="password"
                      className="logInpBtn"
                      placeholder="Comfirm Password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={registerDataChange}
                    />
                  </div>
                  <div>
                    <input type="submit" className="submintBtn" value="Submit" />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* kj */}
        </div>)
      }
    </Fragment>
  );
};

export default InviteForgetPassword;
