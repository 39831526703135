import { CLEAR_ERROR, CREATE_UNIVERSITY_FAIL, CREATE_UNIVERSITY_REQUEST, CREATE_UNIVERSITY_RESET, CREATE_UNIVERSITY_SUCCESS, DELETE_UNIVERSITY_FAIL, DELETE_UNIVERSITY_REQUEST, DELETE_UNIVERSITY_RESET, DELETE_UNIVERSITY_SUCCESS, GET_ALL_ADMIN_UNIVERSITY_FAIL, GET_ALL_ADMIN_UNIVERSITY_REQUEST, GET_ALL_ADMIN_UNIVERSITY_SUCCESS, GET_ALL_UNIVERSITY_FAIL, GET_ALL_UNIVERSITY_REQUEST, GET_ALL_UNIVERSITY_SUCCESS, GET_UNIVERSITY_FAIL, GET_UNIVERSITY_REQUEST, GET_UNIVERSITY_SUCCESS, UPDATE_UNIVERSITY_FAIL, UPDATE_UNIVERSITY_REQUEST, UPDATE_UNIVERSITY_RESET, UPDATE_UNIVERSITY_SUCCESS } from '../constants/universityConstant'




export const getUniversity = (state = { university: [] }, action) => {
  switch (action.type) {
    case GET_UNIVERSITY_REQUEST:
      return {
        loading: true,
      };

    case GET_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        university: action.payload.university,
      };

    case GET_UNIVERSITY_FAIL:
      return {
        ...state,
        loading: false,
        university: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};



export const universityUpdate = (state = { universities: {} }, action) => {
  switch (action.type) {
    case CREATE_UNIVERSITY_REQUEST:
    case UPDATE_UNIVERSITY_REQUEST:
    case DELETE_UNIVERSITY_REQUEST:
      return {
        loading: true,
        isUpdated: false,
      };

    case CREATE_UNIVERSITY_SUCCESS:
    case UPDATE_UNIVERSITY_SUCCESS:
    case DELETE_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        universities: action.payload,
      };

    case CREATE_UNIVERSITY_FAIL:
    case UPDATE_UNIVERSITY_FAIL:
    case DELETE_UNIVERSITY_FAIL:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        universities: null,
        error: action.payload,
      };

    case CREATE_UNIVERSITY_RESET:
    case UPDATE_UNIVERSITY_RESET:
    case DELETE_UNIVERSITY_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        universities: null,
        error: null,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};



export const getAllUniversity = (state = { students: [] }, action) => {
  switch (action.type) {
    case GET_ALL_ADMIN_UNIVERSITY_REQUEST:
    case GET_ALL_UNIVERSITY_REQUEST:
      return {
        loading: true,
      };

    case GET_ALL_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        universities: action.payload.universities,
        totalUniversity: action.payload.totalUniversity,
        resultPerPage: action.payload.resultPerPage,
      };

    case GET_ALL_ADMIN_UNIVERSITY_SUCCESS:
      return {
        ...state,
        loading: false,
        universities: action.payload.universities,
        totalUniversity: action.payload.totalUniversity,
        resultPerPage: action.payload.resultPerPage,
      };




    case GET_ALL_ADMIN_UNIVERSITY_FAIL:
    case GET_ALL_UNIVERSITY_FAIL:
      return {
        ...state,
        loading: false,
        universities: null,
        totalUniversity: null,
        resultPerPage: null,
        error: action.payload,

      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
