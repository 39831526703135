import React, { useEffect } from "react";
import "./Dashboard.css";
import Sidebar from "../../components/Sidebar"
import DashboardHeader from "./DashboardHeader.js";
import { useSelector, useDispatch } from "react-redux";
import { FaFile, FaUserAlt, FaUsers } from "react-icons/fa";
import { clearError, getDashboard } from "../../actions/fileAction";
import MetaData from "../../components/MetaData";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/loading";
import { RiGlobalFill } from "react-icons/ri";
import { TbUserCheck } from "react-icons/tb";
import { ImUserCheck } from "react-icons/im";


const Dashboard = ({ setUnderLine, underLine }) => {
  setUnderLine('dashboard')
  const dispatch = useDispatch();
  const { error, data, loading } = useSelector((state) => state.dashboard);
  const { user } = useSelector((state) => state.users);

  useEffect(() => {
    // dispatch(getFlesData())
    dispatch(getDashboard())

  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
  }, [error])




  //     files.forEach((item) => {
  //       rows.push({
  //         id: item._id,
  //         url: item.file.url,
  //         user_name: item.userId.name,
  //         role: item.userId.role,
  //         createAt: item.createdAt,
  //       });
  //     });

  return (
    <div className="dashboardMain">
      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />
      </div>

      {
        loading ? <Loading /> : (
          <div className="dashboardContainer">
            <DashboardHeader heading={"Dashboard "} />
            <MetaData title={'Dashboard'} />

            {
              user && (user.role === 'admin' ||  user.role === 'supervisor' ) ? (
                <>
                  <div className="D-ButtonLayer">
                    <div className="BlRow">

                      <div className="BlItem BlItem1">
                        <div>
                          <p> Total Users</p>
                          <p>{data?.TotalUser}</p>
                        </div>
                        <div className="">
                          <FaUsers />
                        </div>
                      </div>
                      <div className="BlItem BlItem2">
                        <div>
                          <p> Total Applications</p>
                          <p>{data?.allApplications}</p>
                        </div>
                        <div className="">
                          <FaUsers />
                        </div>
                      </div>
                      <div className="BlItem BlItem3">
                        <div>
                          <p> Total Leads</p>
                          <p>{data?.allLeads || 0}</p>
                        </div>
                        <div className="">
                          <FaUsers />
                        </div>
                      </div>

                      <div className="BlItem BlItem4">
                        <div>
                          <p> Approved Applications </p>
                          <p>{data?.totalApplicationsApproved}</p>
                        </div>
                        <div className="">
                          <ImUserCheck />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="D-ButtonLayer">
                    <div className="BlRow">
                    <div className="BlItem BlItem1">
                        <div>
                          <p> Processed Applications </p>
                          <p>{data?.TotalApplicationsProcessed || 0}</p>
                        </div>
                        <div className="">
                          <FaUserAlt />
                        </div>
                      </div>
                      <div className="BlItem BlItem2">
                        <div>
                          <p>  Applications Assigned</p>
                          <p>{data?.asignedApplications}</p>
                        </div>
                        <div className="">
                          <FaUserAlt />
                        </div>
                      </div>
                      <div className="BlItem BlItem3">
                        <div>
                          <p> Files Uploaded</p>
                          <p>{data?.TotalFilesUploaded}</p>
                        </div>
                        <div className="">
                          <FaFile />
                        </div>
                      </div>


                      <div className="BlItem BlItem4">
                        <div>
                          <p> Counsellors</p>
                          <p>{data?.TotalCounsellors}</p>
                        </div>
                        <div className="">
                          <FaUsers />
                        </div>
                      </div>

                    </div>

                  </div>
                </>
              ) : ''
            }



            {
              user && user?.role === 'processor' || user?.role === 'counsellor' ? (

                <div className="D-ButtonLayer">
                  <div className="BlRow">
                    <div className="BlItem BlItem1">
                      <div>
                        <p> Assigned Applications</p>
                        <p>{data?.newAssignedApplications}</p>
                      </div>
                      <div className="">
                        <RiGlobalFill />

                      </div>
                    </div>
                    <div className="BlItem BlItem2">
                      <div>
                        <p>  Approved Applications  </p>
                        <p>{data?.TotalApplicationsApprovedByUser}</p>
                      </div>
                      <div className="">
                        <RiGlobalFill />

                      </div>
                    </div>
                    <div className="BlItem BlItem3">
                      <div>
                        <p>Processed Applications  </p>
                        <p>{data?.TotalApplicationsProcessed}</p>
                      </div>
                      <div className="">
                        <RiGlobalFill />

                      </div>
                    </div>
                  </div>


                </div>
              ) : ('')
            }

            {
              user && user?.role === 'agent'? (

                <div className="D-ButtonLayer">
                  <div className="BlRow">
                    <div className="BlItem BlItem1">
                      <div>
                        <p>  Applications</p>
                        <p>{data?.totalAppAgentSupervisor}</p>
                      </div>
                      <div className="">
                        <RiGlobalFill />

                      </div>
                    </div>
                    <div className="BlItem BlItem2">
                      <div>
                        <p> File Upload </p>
                        <p>{data?.TotalFileUpload}</p>
                      </div>
                      <div className="">
                        <RiGlobalFill />

                      </div>
                    </div>

                  </div>


                </div>
              ) : ('')
            }

          </div>
        )
      }


    </div>
  );
};

export default Dashboard;
