import "./UpdateProfile.css";

import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  clearError,
  createUser,
  deleteUser,
  getAllUsers,
  loadUser,
  updateProfile,
} from "../../actions/userAction";
import { toast } from "react-toastify";
import { MdEmail, MdVerifiedUser } from "react-icons/md";
import { FiLock } from "react-icons/fi";
import { FaPhone, FaPhotoVideo, FaUser, FaVoicemail } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import Loading from "../../components/Loading/loading";
import { UPDATE_PROFILE_RESET } from "../../constants/userConstant";

const CreateUser = () => {
  // const { error, users } = useSelector((state) => state.allUsers);
  const { error, success, loading } = useSelector((state) => state.createUser);

  const { user } = useSelector((state) => state.users);
  const { error:updateError, isUpdated, loading:updateLoading } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // toast.success("perfect");
  
    if (error) {
      toast.error(error);
      dispatch(clearError())
    }
    if(isUpdated){
      toast.success('Profile Updated Sucessfull.')
      dispatch(loadUser())
      navigate('/profile')
      dispatch({type:UPDATE_PROFILE_RESET})
    }
  }, [error,isUpdated,dispatch]);
  let URL = ''
  if(user?.avtar !== undefined){
    // console.log(user.avtar)
     URL = user?.avtar.url

  }
  const [profilePicuture, setProfilePicuture] = useState("");
  const [profilePicuturePreview, setProfilePicuturePreview] = useState(URL); //jhkkh

  const [updateUser, setUser] = useState({
    name: user?.name,
    email:user?.email,
    phone:user?.phone,
  
  });
  const {name, email, phone } = updateUser;
  
    const updataSubmit = (e)=>{
  
      e.preventDefault();
      
      const myForm = new FormData();
       myForm.set("name",name)
       myForm.set("email",email)
       myForm.set("phone",phone)
       myForm.set("avatar",profilePicuture)

         dispatch(updateProfile(myForm));
       
      }
      const updateDataChange = (e)=>{
        setUser({...updateUser , [e.target.name]: e.target.value})
    };


    const updateProfileDataChange = (e) => {

        const reader = new FileReader();
    
        reader.onload = () => {
          if (reader.readyState === 2) {
            setProfilePicuture(reader.result);
            setProfilePicuturePreview(reader.result);
          }
        };
    
        reader.readAsDataURL(e.target.files[0]);
      };

  return (
    <div className="dashboardMain">
      <div className={window.innerWidth>600 ?"menuContainer show" :"menuContainer hide" }>
        <Sidebar />
      </div>
    {
      loading || updateLoading ? <Loading/> : (
        <div className="dashboardContainer">
        <DashboardHeader heading="Update Profile..." />

        {/* <div className="ButtonLayer">
          <div className="blBox1">
            <div className="totalUser">
              <p>
                Total User <br /> 45
              </p>
            </div>
            <div className="blocklUser">
              <p>
                Block User <br /> 45
              </p>
            </div>
          </div>
        </div> */}
        <div className="createUserContainer">
          <form action="" onSubmit={(e) => updataSubmit(e)}>
            <div className="profileBox">
                <img src={profilePicuturePreview ? profilePicuturePreview : require('../../images/reward1.jpg')} alt="profile" />

            </div>
            <div>
              <FaUser />
              <input
                type="name"
                required
                name="name"
                className="inpBtn"
                placeholder="Name"
                value={name}
                onChange={updateDataChange}
              />
            </div>
            <div>
              <AiFillMail />
              <input
                type="email"
                required
                className="inpBtn"
                name="email"
                placeholder="Eamil"
                value={email}
                onChange={updateDataChange}
              />
            </div>
            {/* <div>
              <MdVerifiedUser />
              <select required value={role} onChange={(e) => setRole(e.target.value)} name="" id="" className="inpBtn">
                <option value="">Select Role</option>
                <option value="supervisor">Supervisor</option>
                <option value="role2">Role 2</option>
                <option value="role3">Role 3</option>
              </select>
            </div> */}
            <div>
              <FaPhone />
              <input
                type="phone"
                placeholder="Phone no"
                name="phone"
                value={phone}

                required
                className="inpBtn"
                onChange={updateDataChange}
              />
            </div>

            <div>
              {/* <FaPhotoVideo /> */}
              <input
                type="file"
                placeholder="Phone no"
                accept="image/*"
                // value={profilePicuture}
                // required
                className=" fileInp"
                onChange={updateProfileDataChange}
              />
            </div>



            <div>
              <input
                type="submit"
                className="submintBtn"
                value={"Update Profile "}
                placeholder="name"
              />
            </div>
          </form>
        </div>
      </div>
      )
    }
    </div>
  );
};

export default CreateUser;
