import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Loading from "../../components/Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import "./StudentUpdate.css";
import { clearError, getStudent, studentdUpdate } from "../../actions/fileAction";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { STUDENT_UPDATE_RESET } from "../../constants/fileConstant";
import { team_name, status as applicationStatus } from "../../components/student";
import DocumentUploadUi from '../../components/DocumentUploadUi.jsx'



const StudentUpdate = () => {
  const dispatch = useDispatch();
  const { error, isUpdated, loading } = useSelector(
    (state) => state.updateStudent
  );
  const {
    error: StudentError,
    student,
    loading: studentLoaging,
  } = useSelector((state) => state.oneStudent);


  const [fields, setFields] = useState([
    // { name: "", file: null, fileType: "" }
  ]);

  // console.log(fields)

  const { user } = useSelector((state) => state.users);


  const { id } = useParams();
  const navigate = useNavigate();

  const [addFile, setAddFile] = useState(["1"]);
  const [selectedMultiFile, setSelectedMultiFile] = useState([]);

  const [selectedFile, setSelectedFile] = useState({
    name: "",
    image: "",
  });


  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [Team_Name, setTeam_Name] = useState("");
  const [comments, setComments] = useState("");
  // const [Lead_from_State, setLead_from_State] = useState("");

  const [studentData, setStudentData] = useState({
    University_Name: '', Lead_from_State: '', Agent_Name: '', Intake: '', Date_Of_Birth: '',
    Region: '', Application_Id: '', Student_Id: '', Course: '', File_Drive_Link: '', visaRejected: ''
  })

  const {
    University_Name, Agent_Name, Intake, Date_Of_Birth, Lead_from_State,
    Region, Course, File_Drive_Link, visaRejected
  } = studentData;




  const studentDataChangeHandler = e => {
    setStudentData({ ...studentData, [e.target.name]: e.target.value })
  }

  const studentDataSetHandler = (key, value) => {
    setStudentData(prevValues => {
      return { ...prevValues, [key]: value }
    })
  }

  useEffect(() => {

    setName(student?.Name)
    setEmail(student?.Email)
    setCountry(student?.Country)
    setStatus(student?.status)
    setPhone(student?.Phone)
    setTeam_Name(student?.Team_Name)
    setComments(student?.comments)

    // setLead_from_State(student?.Lead_from_State)

    if (student != null) {
      const studentDataArray = Object.entries(student);
      studentDataArray?.forEach(([key, value]) => {
        studentDataSetHandler(key, value)
      });
    }

    // 

  }, [studentLoaging]);



  const studentUpdateHandler = async (e) => {
    e.preventDefault();

    //    await dispachFuc();
    if (selectedFile.image !== "" && selectedFile.name !== "") {
      selectedMultiFile.push({ value: selectedFile });
    }

    let data = {
      Name: name, Email: email, Country: country, Phone: phone,
      University_Name, Team_Name, Agent_Name, Intake, Date_Of_Birth,
      Region, Course, Lead_from_State, comments, File_Drive_Link, visaRejected,
      status: fields?.length !== 0 ? status == '1' ? '0' : status : status
    };

    console.log("Status", fields?.length !== 0 === status == '1')

    dispatch(studentdUpdate(id, data, fields));
  };




  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
      // setSelectedMultiFile([])
    }
    if (isUpdated) {
      toast.success("Updated Successfully");
      // if(user?.role==='counsellor'){
      // }else{

      // }
      setFields([]);
      
      navigate(-1);
      dispatch({ type: STUDENT_UPDATE_RESET });
      // setSelectedMultiFile([])
    }
    // dispatch(set)
    if (StudentError) {
      toast.error(StudentError)
      dispatch(clearError())

    }
  }, [error, isUpdated, dispatch, selectedMultiFile, StudentError]);


  useEffect(() => {
    if (id) {
      dispatch(getStudent(id));
    }
  }, [id]);

  return (
    <div className="dashboardMain">
      <div
        className={
          window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
        }
      >
        <Sidebar />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="dashboardContainer">
          {/* <DashboardHeader heading={"Students Application"} /> */}

          <div className="StudentUpdateBox">
            <form action="" onSubmit={studentUpdateHandler}>
              <h3>Update - Student Details </h3>

              {
                // user?.role === 'counsellor' ? '' :
                <>


                  <div className="updateItemBox">
                    <div className="updateInputItem">
                      <p>Name</p>
                      <input
                        type="text"
                        required

                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>

                    <div className="updateInputItem">
                      <p>Email</p>
                      <input
                        type="text"
                        required

                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>



                  <div className="updateItemBox">
                    <div className="updateInputItem">
                      <p>Phone no.</p>
                      <input
                        type="text"
                        required

                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>

                    <div className="updateInputItem">
                      <p>Country </p>
                      <input
                        type="text"

                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div>
                  </div>



                  <div className="updateItemBox">
                    <div className="updateInputItem">
                      <p>University Name</p>
                      <input
                        name="University_Name"
                        type="text"
                        value={University_Name}
                        onChange={studentDataChangeHandler}
                      />
                    </div>

                    {/* <div className="updateInputItem">
                        <p>Lead From State</p>
                        <input
                          name="Lead_from_State"
                          type="text"
                          value={Lead_from_State}
                          onChange={studentDataChangeHandler}
                        /> 

                      </div>
                      */}

                    {/* <div className="updateInputItem">

                        <p>Agent Name</p>
                        <input
                          name="Agent_Name"
                          type="text"
                          value={Agent_Name}
                          onChange={studentDataChangeHandler}
                        />
                      </div> */}
                  </div>

                  <div className="updateItemBox">
                    <div className="updateInputItem">
                      <p>Course</p>
                      <input
                        name="Course"
                        type="text"
                        value={Course}
                        onChange={studentDataChangeHandler}
                      />
                    </div>

                    <div className="updateInputItem">
                      <p>Intake</p>
                      <input
                        name="Intake"
                        type="text"
                        value={Intake}
                        onChange={studentDataChangeHandler}
                      />

                    </div>
                  </div>


                  <div className="updateItemBox">
                    <div className="updateInputItem">
                      <p>Date Of Birth</p>
                      <input
                        name="Date_Of_Birth"
                        type="text"
                        value={Date_Of_Birth}
                        onChange={studentDataChangeHandler}
                      />
                    </div>

                    <div className="updateInputItem">
                      <p>Region</p>
                      <input
                        name="Region"
                        type="text"

                        value={Region}
                        onChange={studentDataChangeHandler}
                      />

                    </div>
                  </div>




                  {/* <div className="updateItemBox">
                      <div className="updateInputItem">
                        <p>Lead From State</p>
                        <input
                          name="Lead_from_State"
                          type="text"
                          value={Lead_from_State}
                          onChange={studentDataChangeHandler}
                        />

                      </div>


                    </div> */}
                  {
                    user?.role !== 'agent' &&
                    < div className="updateItemBox">
                      <div className="updateInputItem">
                        <p>Google Drive Link</p>
                        <input
                          name="File_Drive_Link"
                          type="text"
                          value={File_Drive_Link}
                          onChange={studentDataChangeHandler}
                        />
                      </div>
                    </div>
                  }

                  <br />

                  <div className="updateItemBox">

                    <div className="updateInputItem">

                      <p>Team Name</p>

                      <select name="" id=""
                        value={Team_Name}
                        onChange={(e) => setTeam_Name(e.target.value)}>

                        {
                          team_name?.map((item) => (
                            <option value={item.id}>{item.name}</option>
                          ))
                        }


                      </select>
                    </div>
                  </div>
                </>
              }


              <br />
              <div className="updateItemBox">
                <div className="updateInputItem">
                  <p>If Past Visa Rejected ?</p>
                  <textarea placeholder="value" name="visaRejected" className="visaInput" value={visaRejected} onChange={studentDataChangeHandler} id=""></textarea>
                </div>
              </div>

              {/* <div className="updateItemBox">
                <div className="updateInputItem">
                  <p>Comments</p>
                  <textarea placeholder="Your Name : Your Comments..." name="" className="commentsInput" value={comments} onChange={e => setComments(e.target.value)} id=""></textarea>
                </div>
              </div> */}

              <div className="updateItemBox">

                {
                  user?.role !== 'agent' &&
                  <div className="seletItem">
                    <p>Please Check Status</p>
                    <select name="" id=""
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}>

                      {
                        applicationStatus?.map((item) => (
                          <>
                            {
                              <option value={item.id}>{item.name}</option>
                            }
                          </>
                        ))
                      }


                    </select>

                  </div>
                }

              </div>






              {/* file upload code */}

              <DocumentUploadUi setFields={setFields} fields={fields} />

              {/* {addFile.map((e, index) => (
                <div className="updateInputItem fileItem">
                  <p> {index + 1} File </p>
                  <input
                    type="text"

                    placeholder="Enter File name"
                    onChange={(e) => {
                      setSelectedFile({
                        ...selectedFile,
                        name: e.target.value,
                      });
                    }}
                  />{" "}
                  <br />
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .docx, .pdf, .doc"
                    className="fileBtn"
                    placeholder="Enter File name"
                    onChange={changeFiledata}
                  />
                  <p>Accepted Only : jpg, jpeg, png, docx, pdf, doc. | max-size : 5mb </p>
                </div>
              ))}

              <div className="ActionBox">
                <button onClick={(e) => addFileHandler(e)} className="addBtn">
                  Add File
                </button>
                <button
                  onClick={(e) => closeFileHandler(e)}
                  className="closeBtn"
                >
                  Close
                </button>
              </div> */}

              <div className="submitItem">
                <input type="submit" value={"Update"} />
              </div>
            </form>
          </div>
        </div>
      )
      }
    </div >
  );
};

export default StudentUpdate;
