import React, { Fragment, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FiLock } from "react-icons/fi";
import { MdEmail } from "react-icons/md";
// import "./login.css";

import { useDispatch, useSelector } from "react-redux";
import { clearError, inviteUser, login, register } from '../../actions/userAction'
import { toast } from "react-toastify";
import { useEffect } from "react";
import Loading from "../../components/Loading/loading";

const Register = () => {
  const { error, success, loading } = useSelector((state) => state.createUser);

const dispatch = useDispatch();
const navigate = useNavigate();

const {token} = useParams();

console.log("token",token)

const [user, setUser] = useState({
  name: "",
  password:"", 
  confirmPassword:""

});
const {name,  password,confirmPassword } = user;

useEffect(() => {
  if(error){
    toast.error(error)
  }
  if(success){
    toast.success('Account Created Sucessfully , Please Login')
    navigate('/')
   }
}, [error,success]);
  const registerSubmit = (e)=>{
 console.log(name, password,confirmPassword)

    e.preventDefault();
    
    const myForm = new FormData();
     myForm.set("name",name)
     myForm.set("password",password)
    //  myForm.set("confirmPassword",confirmPassword)
    
     if(password != confirmPassword){
      toast.error("Password not match")
     }
     else{
       dispatch(inviteUser(myForm,token));
       if(success){
        toast.success('Account Created Sucessfully , Please Login')
        navigate('/')
       }
     }
    }
    const registerDataChange = (e)=>{
      setUser({...user , [e.target.name]: e.target.value})
  };
  return (
    
      <Fragment>
        {
          loading ? <Loading/> : ( <div className="loginContainer">
      <div className="imgBox">
        <img src={require("../../images/gleam-login.png")} alt="image" />
      </div>
      <div>
        <div className="formBox">
          <h2>Register Now  </h2>  
          <form action="" onSubmit={registerSubmit}>
            <div>
              <FaUser />
              <input type="text" className="logInpBtn" placeholder="Name" name="name" value={name} onChange={registerDataChange} />
            </div>
            
            <div>
              <FiLock />
              <input
                type="password"
                className="logInpBtn"
                placeholder="Password"
                value={password} onChange={registerDataChange}
                name="password"
              />
            </div>
            <div>
              <FiLock />
              <input
                type="password"
                className="logInpBtn"
                placeholder="Comfirm Password"
                name="confirmPassword"
                value={confirmPassword} onChange={registerDataChange}
              />
            </div>
            <div>
              <input type="submit" className="submintBtn" value="Register" />

            </div>
          </form>
         
        </div>
      </div>
    </div>)
}
      </Fragment>
      
  );
};

export default Register;
