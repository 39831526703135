import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError } from '../../actions/userAction';
import { getMonitorUsersAction } from '../../actions/monitoringAction';
import './selectMonitor.css'
import { status } from '../../components/student';

const SelectMonitorUserUI = ({ setId }) => {

    const { error, users, resultPerPage, totalUsers, loading } = useSelector((state) => state.getMonitorUsers);
    const { user,isAuthenticated } = useSelector((state) => state.users);
    const [userRole, setUserRole] = useState('')

    const dispatch = useDispatch();
    const Navigate = useNavigate();

    const [selUser, setSelUser] = useState(null);
    const [selDevice, setSelDevice] = useState(null);
    const [device, setDevice] = useState(null);



    useEffect(() => {
        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);

    useEffect(() => {
        dispatch(getMonitorUsersAction())
    }, [])

    useEffect(() => {
        if(isAuthenticated)
        if (user?.role !== 'admin') {
            Navigate('/dashboard');
        }
    }, [user])



    useEffect(() => {
        // console.log(selDevice);

        // if (selDevice?.serialNumber && selUser?._id) {
        //     window.localStorage.setItem('sno', selDevice?.serialNumber);
        //     window.localStorage.setItem('id', selUser?._id);
        // }

        setId(selUser);
        // setSno(selDevice?.serialNumber)

        // console.log(selDevice?.serialNumber, selUser?._id)

    }, [selUser]);



    const handleSelectUser = (user) => {
        try {
            setSelUser(JSON.parse(user))
        } catch (error) {

        }
    }

    // const userRole = []
// const setUserRole = 

const role = ['admin','agent','counsellor','supervisor','processor'];


    return (
        <div className='monitorUserContainer'>

            <div>
                <div className="updateItemBox">

                    <div className="updateInputItem">
                        <p className='textWhite'>Select Role<b style={{ color: 'red' }}>*</b></p>

                        <div className='roleBox'>
                            {
                                role?.map(item => (
                                    <button className={item === userRole ? 'activeRole roleBtn': 'roleBtn'} onClick={e=>setUserRole(item)}>{item}</button>
                                ))}
                        </div>

                        <p className='textWhite'>Select User Required <b style={{ color: 'red' }}>*</b></p>
                        <select name="" id="" placeholder="value"
                            value={JSON.stringify(selUser)}
                            onChange={e => handleSelectUser(e.target.value)}>
                            <option value={''}>Select</option>

                            {
                                users?.map((item) => (
                                    <>
                                        {
                                            item.role === userRole &&
                                            <option value={JSON.stringify(item)}>{item.name} - {item.email} - {item.role}</option>
                                        }
                                    </>
                                ))
                            }
                        </select>
                        {/* <p>{selUser?.email}</p> */}

                    </div>


                    {/* {
                        device?.length !== 0 &&

                        <div className="updateInputItem">
                            <p className='textWhite'>Select Device Required <b style={{color:'red'}}>*</b></p>
                            <select name="" id="" placeholder="Device"
                                value={JSON.stringify(selDevice)}
                                onChange={e => setSelDevice(JSON.parse(e.target.value))}>
                                {
                                    device?.map((item) => (
                                        <option value={JSON.stringify(item)}>{item.manufacturer}</option>
                                    ))
                                }
                            </select>
                        </div>
                    } */}

                </div>
            </div>

        </div>
    )
}

export default SelectMonitorUserUI