export const CREATE_UNIVERSITY_REQUEST = "CREATE_UNIVERSITY_REQUEST"
export const CREATE_UNIVERSITY_SUCCESS = "CREATE_UNIVERSITY_SUCCESS"
export const CREATE_UNIVERSITY_FAIL = "CREATE_UNIVERSITY_FAIL"
export const CREATE_UNIVERSITY_RESET = "CREATE_UNIVERSITY_RESET"

export const UPDATE_UNIVERSITY_REQUEST = "UPDATE_UNIVERSITY_REQUEST"
export const UPDATE_UNIVERSITY_SUCCESS = "UPDATE_UNIVERSITY_SUCCESS"
export const UPDATE_UNIVERSITY_FAIL = "UPDATE_UNIVERSITY_FAIL"
export const UPDATE_UNIVERSITY_RESET = "UPDATE_UNIVERSITY_RESET"

export const DELETE_UNIVERSITY_REQUEST = "DELETE_UNIVERSITY_REQUEST"
export const DELETE_UNIVERSITY_SUCCESS = "DELETE_UNIVERSITY_SUCCESS"
export const DELETE_UNIVERSITY_FAIL = "DELETE_UNIVERSITY_FAIL"
export const DELETE_UNIVERSITY_RESET = "DELETE_UNIVERSITY_RESET"

export const GET_UNIVERSITY_REQUEST = "GET_UNIVERSITY_REQUEST"
export const GET_UNIVERSITY_SUCCESS = "GET_UNIVERSITY_SUCCESS"
export const GET_UNIVERSITY_FAIL = "GET_UNIVERSITY_FAIL"

export const GET_ALL_UNIVERSITY_REQUEST = "GET_ALL_UNIVERSITY_REQUEST"
export const GET_ALL_UNIVERSITY_SUCCESS = "GET_ALL_UNIVERSITY_SUCCESS"
export const GET_ALL_UNIVERSITY_FAIL = "GET_ALL_UNIVERSITY_FAIL"

export const GET_ALL_ADMIN_UNIVERSITY_REQUEST = "GET_ALL_ADMIN_UNIVERSITY_REQUEST"
export const GET_ALL_ADMIN_UNIVERSITY_SUCCESS = "GET_ALL_ADMIN_UNIVERSITY_SUCCESS"
export const GET_ALL_ADMIN_UNIVERSITY_FAIL = "GET_ALL_ADMIN_UNIVERSITY_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"