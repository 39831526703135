import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { profiletReducers, usertReducers, forgotPasswordReducers, allUsersReducers, userDetailsReducers, createUserReducer, sendOtpReducer } from './reducers/userReducer'
import { fileUpload, getFiles, getStudents, studentUpdate, getStudent, asign, getAsignedApplications, getDashboard, userReport } from './reducers/fileReducer';
import { getAllUniversity, getUniversity, universityUpdate } from './reducers/universityReducer';
import { getMonitorUser, getMonitorUsers, getMonitorApi, getTracks, createTrack, getTrack } from './reducers/moniteringReducer';
import { getAllResources, getResource, resourceUpdate } from './reducers/resourceReducer';
import { getComments, updateComment } from './reducers/commentReducer';


const reducer = combineReducers({
    profile: profiletReducers,
    users: usertReducers,
    allUsers: allUsersReducers,
    forgetPassword: forgotPasswordReducers,
    createUser: createUserReducer,
    getUser: userDetailsReducers,
    sendOtp: sendOtpReducer,

    fileUpload: fileUpload,
    students: getStudents,
    files: getFiles,
    updateStudent: studentUpdate,
    oneStudent: getStudent,
    asign: asign,
    asignApplications: getAsignedApplications,
    report: userReport,
    dashboard: getDashboard,

    universityUpdate,
    getUniversity,
    getAllUniversity,

    getMonitorUsers,
    getMonitorUser,
    getMonitorApi,


    getResource,
    getAllResources,
    resourceUpdate,

    getComments,
    updateComment,

    getTrack,
    getTracks,
    createTrack

});

let intitialeState = {

};

const middleware = [thunk]

const store = createStore(
    reducer,
    intitialeState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store;
