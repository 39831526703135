import "./UpdateUser.css";

import React, { useState, useEffect, Fragment } from "react";
import Sidebar from "../../components/Sidebar";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import {
  clearError,
  getUserDetails,
  updateUser
} from "../../actions/userAction";

import { toast } from "react-toastify";
import { MdEmail, MdPhone, MdVerifiedUser } from "react-icons/md";
import { FiLock } from "react-icons/fi";
import { FaPhone, FaPhotoVideo, FaUser, FaVoicemail } from "react-icons/fa";
import { AiFillMail } from "react-icons/ai";
import Loading from "../../components/Loading/loading";
import { UPDATE_USER_RESET } from "../../constants/userConstant";

const UpdateUser = () => {
  // const { error, users } = useSelector((state) => state.allUsers);
  //   const { error, success, loading } = useSelector((state) => state.createUser);

  const { user, loading, error } = useSelector((state) => state.getUser);
  const { error: updateError, isUpdated, loading: updateLoading } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();


  useEffect(() => {
    // toast.success("perfect");
    dispatch(getUserDetails(id))

    if (error) {
      toast.error(error);
    }
    if (updateError) {
      toast.error(updateError);
    }
    if (isUpdated) {
      toast.success('Updated Sucessfull.')
      navigate('/user')
      dispatch({ type: UPDATE_USER_RESET });
    }
  }, [error, id, isUpdated]);
  const [profilePicuture, setProfilePicuture] = useState(user?.a);

  const [updateUsers, setUser] = useState({
    name: user?.name,
    role: user?.role,
    isActive: user?.isActive,
    email: user?.email,
    phone: user?.phone
  });
  const { role, isActive, email, phone, name } = updateUsers;

  const updataSubmit = (e) => {

    e.preventDefault();

    const myForm = new FormData();
    myForm.set("isActive", isActive)
    myForm.set("role", role)
    myForm.set("email", email)
    myForm.set("phone", phone)
    myForm.set("name", name)

    const myFormData = {
      isActive, role, email, phone, name
    }

    // console.log(myFormData)
    //    myForm.set("phone",phone)
    //    myForm.set("avatar",profilePicuture)

    dispatch(updateUser(id, myFormData));

  }
  const updateDataChange = (e) => {
    setUser({ ...updateUsers, [e.target.name]: e.target.value })
  };
  useEffect(() => {
    setUser({ isActive: user?.isActive, role: user?.role, email: user?.email, phone: user?.phone, name: user?.name })
  }, [user])


  // const updateProfileDataChange = (e) => {

  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setProfilePicuture(reader.result);
  //       }
  //     };

  //     reader.readAsDataURL(e.target.files[0]);
  //   };

  console.log(user)

  return (
    <div className="dashboardMain">
      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar />
      </div>
      {
        loading || updateLoading ? <Loading /> : (
          <div className="dashboardContainer">
            <DashboardHeader heading="Update User..." />

            {/* <div className="ButtonLayer">
          <div className="blBox1">
            <div className="totalUser">
              <p>
                Total User <br /> 45
              </p>
            </div>
            <div className="blocklUser">
              <p>
                Block User <br /> 45
              </p>
            </div>
          </div>
        </div> */}
            <div className="createUserContainer">
              <form action="" onSubmit={(e) => updataSubmit(e)}>
                <div className="profileBox">
                  <img src={profilePicuture ? profilePicuture : require('../../images/user-icon.png')} alt="profile" />
                </div>

                <div>
                  <MdEmail />
                  <input type="text" name="name" onChange={updateDataChange} value={name} className="inpBtn" />
                </div>

                <div>
                  <MdEmail />
                  <input type="email" name="email" onChange={updateDataChange} value={email} className="inpBtn" />
                </div>
                <div>
                  <MdPhone />
                  <input type="number" name="phone" onChange={updateDataChange} value={phone} className="inpBtn" />
                </div>
                <div>
                  <MdVerifiedUser />
                  <select required value={role} onChange={updateDataChange} name="role" id="1" className="inpBtn">
                    <option value="">Select Role</option>
                    <option value="admin">admin</option>
                    <option value="counsellor">counsellor</option>
                    <option value="supervisor">supervisor</option>
                    <option value="agent">agent</option>
                    <option value="processor">processor</option>
                  </select>
                </div>
                <div>
                  <MdVerifiedUser />
                  <select required value={isActive} onChange={updateDataChange} name="isActive" id="2" className="inpBtn">
                    <option value="">Select Actived</option>
                    <option value='true'>active</option>
                    <option value="false">InActive</option>
                    {/* <option value="role3">Role 3</option> */}
                  </select>
                </div>

                {/* <div>
              <FaPhotoVideo />
              <input
                type="file"
                placeholder="Phone no"
                accept="image/*"
                // value={profilePicuture}
                required
                className="inpBtn"
                onChange={updateProfileDataChange}
              />
            </div> */}



                <div>
                  <input
                    type="submit"
                    className="submintBtn"
                    value={"Update Profile "}
                    placeholder="name"
                  />
                </div>
              </form>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default UpdateUser;
