


import axios from "axios";

import host from "../utils/var";
import { CREATE_UNIVERSITY_REQUEST } from "../constants/universityConstant.js";
import { CREATE_UNIVERSITY_SUCCESS } from "../constants/universityConstant.js";
import { CREATE_UNIVERSITY_FAIL } from "../constants/universityConstant.js";
import { UPDATE_UNIVERSITY_SUCCESS } from "../constants/universityConstant.js";
import { UPDATE_UNIVERSITY_REQUEST } from "../constants/universityConstant.js";
import { UPDATE_UNIVERSITY_FAIL } from "../constants/universityConstant.js";
import { GET_UNIVERSITY_REQUEST } from "../constants/universityConstant.js";
import { GET_UNIVERSITY_SUCCESS } from "../constants/universityConstant.js";
import { GET_UNIVERSITY_FAIL } from "../constants/universityConstant.js";
import { CLEAR_ERROR } from "../constants/universityConstant.js";
import { GET_ALL_UNIVERSITY_REQUEST } from "../constants/universityConstant.js";
import { GET_ALL_UNIVERSITY_SUCCESS } from "../constants/universityConstant.js";
import { GET_ALL_UNIVERSITY_FAIL } from "../constants/universityConstant.js";
import { DELETE_UNIVERSITY_SUCCESS } from "../constants/universityConstant.js";
import { DELETE_UNIVERSITY_REQUEST } from "../constants/universityConstant.js";
import { DELETE_UNIVERSITY_FAIL } from "../constants/universityConstant.js";




export const createUniversityAction = (details) => async (dispatch) => {
    try {
      dispatch({ type: CREATE_UNIVERSITY_REQUEST });
      const config = { withCredentials: true };
  
      const data = await axios.post(`${host}/api/v1/create-university`,details, config);
  
      dispatch({ type:  CREATE_UNIVERSITY_SUCCESS, payload: data.data });
  
    } catch (error) {
      dispatch({
        type:  CREATE_UNIVERSITY_FAIL,
        payload: error?.response?.data.message,
      });
    }
  };


 

  export const updateUniversityAction = (universityId,details) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_UNIVERSITY_REQUEST });
      const config = { withCredentials: true };
  
      const data = await axios.put(`${host}/api/v1/update-university/${universityId}`,details, config);
  
      dispatch({ type: UPDATE_UNIVERSITY_SUCCESS, payload: data.data });
  
    } catch (error) {
      dispatch({
        type: UPDATE_UNIVERSITY_FAIL,
        payload: error?.response?.data.message,
      });
    }
  };

  export const getUniversityAction = (universityId) => async (dispatch) => {
    try {
      dispatch({ type: GET_UNIVERSITY_REQUEST });
      const config = { withCredentials: true };
  
      const data = await axios.get(`${host}/api/v1/get-university/${universityId}`, config);
  
      dispatch({ type: GET_UNIVERSITY_SUCCESS, payload: data.data });
  
    } catch (error) {
      dispatch({
        type: GET_UNIVERSITY_FAIL,
        payload: error?.response?.data.message,
      });
    }
  };

  export const getAllUniversityAction = (page,keyword) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_UNIVERSITY_REQUEST });
      const config = { withCredentials: true };
  
      const data = await axios.get(`${host}/api/v1/get-all-universities?page=${page}&keyword=${keyword}`, config);
  
      dispatch({ type: GET_ALL_UNIVERSITY_SUCCESS, payload: data.data });
  
    } catch (error) {
      dispatch({
        type: GET_ALL_UNIVERSITY_FAIL,
        payload: error?.response?.data.message,
      });
    }
  };

  export const getAllAdminUniversityAction = (universityId,page,keyword) => async (dispatch) => {
    try {
      dispatch({ type: GET_ALL_UNIVERSITY_REQUEST });
      const config = { withCredentials: true };
  
      const data = await axios.get(`${host}/api/v1/get-all-universities-admin/${universityId}?page=${page}&keyword=${keyword}`, config);
  
      dispatch({ type: GET_ALL_UNIVERSITY_SUCCESS, payload: data.data });
  
    } catch (error) {
      dispatch({
        type: GET_ALL_UNIVERSITY_FAIL,
        payload: error?.response?.data.message,
      });
    }
  };

  export const deleteUniversityAction = (_id) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_UNIVERSITY_REQUEST });
      const config = { withCredentials: true };
  
      const data = await axios.put(`${host}/api/v1/delete-university/${_id}`,{}, config);
  
      dispatch({ type:  DELETE_UNIVERSITY_SUCCESS, payload: data.data });
  
    } catch (error) {
      dispatch({
        type:  DELETE_UNIVERSITY_FAIL,
        payload: error?.response?.data.message,
      });
    }
  };

  export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
  };
  
  