import "./sidebar.css";
// import logo from '../../image
import { useEffect } from "react";
import { FaClock, FaHouseUser, FaImage, FaMapMarkedAlt, FaRegFileAlt, FaTv, FaUniversity, FaUserCheck, FaUserClock, FaUsers, FaVideo } from "react-icons/fa";
import { ImUserPlus } from "react-icons/im";
import { RxDashboard } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../actions/userAction";
import Logo from '../images/gleam_logo.png'
import { MdWorkHistory } from "react-icons/md";

// import ExpandMoreIcon from '@mui/material'
// import AddIcon from '@mui/mate
// import ImportExportIcon from '@mui/material'

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);

    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


const Sidebar = ({ underLine }) => {
    const { error, user, isAuthenticated, loading } = useSelector((state) => state.users);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const token = Cookies.get('token');
    // console.log('Token:', token);

    const token = getCookie('token');

    // console.log(token);

    useEffect(() => {
        if (error) {
            toast.error(error);
        }


        if (loading === false)
            if (!isAuthenticated) {
                navigate("/");
            }
            
        if (!token) {
            dispatch(logout())
        }
    }, [error]);

    // const logOutHandler = () => {
    //   dispatch(logout());
    //   navigate("/");
    // };

    const clearNavbar = () => {

        if (window.innerWidth <= 600) {

            document.getElementsByClassName("menuContainer")[0].classList.add("hide");
            document
                .getElementsByClassName("menuContainer")[0]
                .classList.remove("show");
        }
    };
    return (
        <div className="sideBarContainer">
            <div className="sidebar">
                <p className="BrandLogo">
                    {/* <img
            src={Logo}
            alt="GLEAM"
          /> */}
                    <h1>Submit App</h1>
                </p>

                <Link to="/dashboard" className={underLine === 'dashboard' ? "active" : ""} onClick={(e) => {
                    clearNavbar();
                }} >
                    <p>
                        <RxDashboard />
                        Dashboard
                    </p>
                </Link>

                <h4>MONITORING</h4>

                {
                    user?.role === 'admin' &&
                    (
                        <Link
                            to="/monitoring"
                            onClick={(e) => {
                                clearNavbar();
                            }}
                            className={underLine === 'monitoring' ? "active" : ""}
                        >
                            <p>
                                <FaTv />
                                Users Monitoring
                            </p>
                        </Link>


                    )
                }

                {
                    user?.role === 'admin' ? (

                        <>
                            <Link
                                to="/active-app"
                                onClick={(e) => {
                                    clearNavbar();
                                }}
                                className={underLine === 'active-apps' ? "active" : ""}>
                                <p>
                                    <FaMapMarkedAlt />
                                    App History
                                </p>
                            </Link>

                            <Link
                                to="/clock-in-out"
                                onClick={(e) => {
                                    clearNavbar();
                                }}
                                className={underLine === 'logs' ? "active" : ""}>
                                <p>
                                    <FaClock />
                                    Clock In-Out
                                </p>
                            </Link>

                            <Link
                                to="/screenshots"
                                onClick={(e) => {
                                    clearNavbar();
                                }}
                                className={underLine === 'screenshots' ? "active" : ""}>
                                <p>
                                    <FaImage />
                                    Screenshots
                                </p>
                            </Link>

                            <Link
                                to="/recordings"
                                onClick={(e) => {
                                    clearNavbar();
                                }}
                                className={underLine === 'recordings' ? "active" : ""}>
                                <p>
                                    <FaVideo />
                                    Recordings
                                </p>
                            </Link>

                            <Link
                                to="/report"
                                onClick={(e) => {
                                    clearNavbar();
                                }}
                                className={underLine === 'report' ? "active" : ""}>
                                <p>
                                    <MdWorkHistory />
                                    Reports
                                </p>
                            </Link>

                            <Link
                                to="/track-screenshots"
                                onClick={(e) => {
                                    clearNavbar();
                                }}
                                className={underLine === 'tracks' ? "active" : ""}>
                                <p>
                                    <MdWorkHistory />
                                    Screenshot Tracking
                                </p>
                            </Link>
                        </>
                    ) : ""}



            </div>

            <p className="designAvenueAD" >
                <a href="https://www.gleamrecruits.com/" target="_black" className="designAvenueAD">Powered by Gleam Education</a>
                Developed and managed by
                <a href="https://www.designavenue.co.in" target="_black" className="">Design Avenue</a>
            </p>


        </div>
    );
};

export default Sidebar;
