import React, { useEffect, useState } from "react";
import { FaDownload, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserReport } from "../../actions/fileAction.js";
import { clearError } from "../../actions/monitoringAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import SidebarMonitor from "../../components/SidebarMonitor.js";
import AsignedHeader from "../../Pages/FileMangement/AsignedHeader";
import "./monitorActiveApps.css";
import SelectUserUI from "./SelectUserUI.js";
import './report.css'
import * as XLSX from "xlsx";
import { getStatus } from "../../components/student.js";




const StudentsApplication = ({ underLine, setUnderLine }) => {
    setUnderLine('report');


    const dispatch = useDispatch();
    const { error, data, loading } = useSelector((state) => state.report);
    const { user,isAuthenticated } = useSelector((state) => state.users);

    useEffect(() => {
        if (error) {
            console.log(error)
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);

    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);

    const formattedDate = today.toISOString().split('T')[0];
    const nextDate = nextDay.toISOString().split('T')[0];

    const [date1, setDate1] = useState(formattedDate);
    const [date2, setDate2] = useState(nextDate);
    const [id, setId] = useState('');

    // useEffect(() => {
    //     searchHandler()
    // }, [id]);


    console.log(data)

    const Navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated)
            if (user?.role !== 'admin') {
                Navigate('/dashboard');
            }
    }, [user])

    const searchHandler = () => {
        dispatch(getUserReport({ id: id?._id, date: date1, date2 }));
    }
    const searchByHandler = () => {
        dispatch(getUserReport({ id: id?._id, date: date1, date2 }));
    }

    const downloadExcelFileWithSummary = (totalLeads = 85, totalFiles = 45) => {

        const apps = data?.applications
        let applications = []


        if (!applications) {
            toast.warn("Empty data")
            return
        }

        apps?.forEach(item => {

            applications.push({
                studentID: item?.Student_Id,
                Name: item?.Name,
                Email: item?.Email,

                Phone: item?.Phone,
                University_Name: item?.University_Name,
                Date_Of_Birth: item?.Date_Of_Birth,
                Course: item?.Course,
                Country: item?.Country,

                Status: getStatus(item?.status),
            })

        });

        // Create a worksheet from the applications array
        const worksheet = XLSX.utils.json_to_sheet(applications);


        const summaryStartRow = applications.length + 5;

        XLSX.utils.sheet_add_aoa(worksheet, [
            [` Name : ${id?.name}`, `Email : ${id?.email}`, `Role : ${id?.role}`],
        ], { origin: `A${summaryStartRow}` });

        XLSX.utils.sheet_add_aoa(worksheet, [
            [` Date : ${date1} to ${date2}`],

            [`Total Applications / Leads : ${data?.applications?.length}`],
            [`Active Applications : ${data?.apps}`],
            [`Active Leads : ${data?.leads}`],
            [`Total Applications Approved : ${data?.approved}`],
            [`Total Applications Rejected : ${data?.rejects}`],
            [`Total Applications Pending : ${data?.pending}`],
            [`Total Applications Processing : ${data?.processing}`]
        ], { origin: `A${summaryStartRow + 7}` });

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Gleam applications");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "gleam_applications_with_summary.xlsx");
    };




    return (
        <div className="dashboardMain">
            <MetaData title={"Monitoring"} />

            <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
                <SidebarMonitor underLine={underLine} />
            </div>


            <div className="dashboardContainer">
                <AsignedHeader heading={"Reports"} />
                <SelectUserUI setId={setId} />


                {
                    loading ? <Loading /> : (

                        <>

                            <div className="D-ButtonLayer">

                                <div className="BlRow1">
                                    {/* <div className="BlItem BlItem4">
                                        <div>
                                            <p>Reports</p>
                                            <p>{totalScreenshots}</p>
                                        </div>
                                        <div className="">
                                            <FaImage />
                                        </div>
                                    </div> */}

                                    <div className="student-date-box">
                                        <p style={{ paddingBottom: '7px' }}>Both Date Required <b style={{ color: 'red' }}>*</b></p>

                                        <form action="" onSubmit={searchByHandler}>
                                            <input type="date" placeholder="Select Date" className="docBtn1" value={date1} onChange={e => setDate1(e.target.value)} />
                                            to
                                            <input type="date" placeholder="Select Date" className="docBtn1" value={date2} onChange={e => setDate2(e.target.value)} />
                                            <button type="submit" className="docBtn1" onClick={searchByHandler} value={'find'} ><FaSearch /></button>
                                        </form>
                                    </div>
                                </div>

                            </div>


                            {
                                loading ? <div className="loadingText">Loading</div> :

                                    <div className="reportContainer">
                                        <div className="reportBox">
                                            <div className="report-headingBox">
                                                <h2>User Reports</h2>

                                                <button className="downloadBtn" onClick={downloadExcelFileWithSummary}><FaDownload /></button>
                                            </div>

                                            <div className="reportHeading">
                                                User details
                                            </div>

                                            <div className="userBox">
                                                <h4>Name : {id?.name}</h4>
                                                <h4>Email : {id?.email}</h4>
                                                <h4>Role : {id?.role}</h4>

                                            </div>

                                            <div className="reportHeading">
                                                Details
                                            </div>
                                            <div className="date-box">
                                                Date : {date1} to {date2}
                                            </div>
                                            <div className="date-box">
                                                Total Applications / Leads: {data?.applications?.length}
                                            </div>
                                            <div className="numBox">
                                                {/* <h4>Total Applications / Leads : {data?.applications && data?.applications?.length}</h4> */}
                                                {/* <h4></h4>  */}

                                                <h4>Active Applications : {data?.apps}</h4>
                                                <h4>Active Leads : {data?.leads}</h4>
                                                <h4>Total Applications Approved: {data?.approved}</h4>
                                                <h4>Total Applications Rejected : {data?.rejects}</h4>
                                                <h4>Total Applications Pending : {data?.pending}</h4>
                                                <h4>Total Applications Processing : {data?.processing}</h4>
                                            </div>
                                        </div>
                                    </div>
                            }




                        </>
                    )
                }
            </div>


        </div>
    );
};

export default StudentsApplication;
