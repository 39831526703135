exports.getDate = (date) => {
    let dates = new Date(date)

    const d = dates.toString().split('G')[0]
    return `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} `
}

exports.getDateTime = (date) => {
    // let dates = new Date(date)
    // console.log(date)
    // const d = dates.toString().split('G')[0]

    // return  `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} - ${d.split(' ')[4]}  `

    const d = date?.toString().split('.')[0]

    const d2 = d?.toString().split('T')

    return d2 ? `${d2[0]} ${d2[1]} ` : "--"

}

exports.getDateTimeIST = (date) => {
    // let dates = new Date(date)
    // console.log(date)
    // const d = dates.toString().split('G')[0]

    // return  `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]} - ${d.split(' ')[4]}  `

    date = new Date(date)
    const d = date?.toString().split('.')[0]

    const d2 = d?.toString().split('T')

    return d2 ? `${d2[0]} ` : "--"

}


exports.getTime = (time) => {

    const t = time.split('-')

    return `${t[0]}:${t[1]}:${t[2]} `
}


exports.getAssignAt = (user, users) => {


    const assUser = users.find(item => item.userId === user._id);

    // console.log(assUser, assUser?.assigndAt)


    let dates = new Date(assUser?.assigndAt)

    const d = dates.toString().split('G')[0]


    return `${d.split(' ')[0]} ${d.split(' ')[1]}  ${d.split(' ')[2]}  ${d.split(' ')[3]}  ${d.split(' ')[4]}`


}


exports.isEditComment = (createAt) => {

    const now = new Date();
    const createdAtDate = new Date(createAt);

    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - createdAtDate;

    // Convert the difference to minutes
    const diffInMinutes = diffInMilliseconds / (1000 * 60);

    // Check if the difference is within 30 minutes
    return diffInMinutes <= 30;

}

exports.isDeleteComment = (createAt) => {


    const now = new Date();
    const createdAtDate = new Date(createAt);
    
    // Calculate the difference in milliseconds
    const diffInMilliseconds = now - createdAtDate;
    
    // Convert the difference to hours
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    
    // Check if the difference is within 24 hours
    return diffInHours <= 24;


}