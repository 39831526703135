import axios from "axios";

import host from "../utils/var";

import { CREATE_RESOURCE_REQUEST, GET_ALL_MY_RESOURCE_FAIL, GET_ALL_MY_RESOURCE_REQUEST, GET_ALL_MY_RESOURCE_SUCCESS } from "../constants/resourceConstant.js";
import { CREATE_RESOURCE_SUCCESS } from "../constants/resourceConstant.js";
import { CREATE_RESOURCE_FAIL } from "../constants/resourceConstant.js";
import { UPDATE_RESOURCE_SUCCESS } from "../constants/resourceConstant.js";
import { UPDATE_RESOURCE_REQUEST } from "../constants/resourceConstant.js";
import { UPDATE_RESOURCE_FAIL } from "../constants/resourceConstant.js";
import { GET_RESOURCE_REQUEST } from "../constants/resourceConstant.js";
import { GET_RESOURCE_SUCCESS } from "../constants/resourceConstant.js";
import { GET_RESOURCE_FAIL } from "../constants/resourceConstant.js";
import { CLEAR_ERROR } from "../constants/resourceConstant.js";
import { GET_ALL_RESOURCE_REQUEST } from "../constants/resourceConstant.js";
import { GET_ALL_RESOURCE_SUCCESS } from "../constants/resourceConstant.js";
import { GET_ALL_RESOURCE_FAIL } from "../constants/resourceConstant.js";
import { DELETE_RESOURCE_SUCCESS } from "../constants/resourceConstant.js";
import { DELETE_RESOURCE_REQUEST } from "../constants/resourceConstant.js";
import { DELETE_RESOURCE_FAIL } from "../constants/resourceConstant.js";




export const createResourceAction = (details) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_RESOURCE_REQUEST });
        const config = { withCredentials: true };

        const data = await axios.post(`${host}/api/v1/create-resource`, details, config);

        dispatch({ type: CREATE_RESOURCE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: CREATE_RESOURCE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};




export const updateResourceAction = (resourceId, details) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_RESOURCE_REQUEST });
        const config = { withCredentials: true };

        const data = await axios.put(`${host}/api/v1//update-resources/${resourceId}`, details, config);

        dispatch({ type: UPDATE_RESOURCE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: UPDATE_RESOURCE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getResourceAction = (resourceId) => async (dispatch) => {
    try {
        dispatch({ type: GET_RESOURCE_REQUEST });
        const config = { withCredentials: true };

        const data = await axios.get(`${host}/api/v1/resource/${resourceId}`, config);

        dispatch({ type: GET_RESOURCE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_RESOURCE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllResourceAction = (page, keyword) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_RESOURCE_REQUEST });
        const config = { withCredentials: true };

        const data = await axios.get(`${host}/api/v1/get-notice-resources?page=${page}&keyword=${keyword}`, config);

        dispatch({ type: GET_ALL_RESOURCE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_ALL_RESOURCE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllMyResourceAction = (page, keyword) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MY_RESOURCE_REQUEST });
        const config = { withCredentials: true };

        const data = await axios.get(`${host}/api/v1/get-my-resources?page=${page}&keyword=${keyword}`, config);

        dispatch({ type: GET_ALL_MY_RESOURCE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_ALL_MY_RESOURCE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const deleteResourceAction = (_id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_RESOURCE_REQUEST });
        const config = { withCredentials: true };

        const data = await axios.put(`${host}/api/v1/delete-resource/${_id}`, {}, config);

        dispatch({ type: DELETE_RESOURCE_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: DELETE_RESOURCE_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

