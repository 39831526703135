import React from 'react'
import'./noDataFound.css'
import { MdNotInterested } from 'react-icons/md'

const NoDataFound = () => {
  return (
    <div className="noDataContainer">
        <div>
            <img src={require('../images/no-data.avif')} alt="" />
            {/* <MdNotInterested/> */}
            <h2>NO DATA FOUND</h2>
        </div>
    </div>

  )
}

export default NoDataFound