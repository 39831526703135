import { CLEAR_ERROR, CREATE_RESOURCE_FAIL, CREATE_RESOURCE_REQUEST, CREATE_RESOURCE_RESET, CREATE_RESOURCE_SUCCESS, DELETE_RESOURCE_FAIL, DELETE_RESOURCE_REQUEST, DELETE_RESOURCE_RESET, DELETE_RESOURCE_SUCCESS, GET_ALL_MY_RESOURCE_FAIL, GET_ALL_MY_RESOURCE_REQUEST, GET_ALL_MY_RESOURCE_SUCCESS, GET_ALL_RESOURCE_FAIL, GET_ALL_RESOURCE_REQUEST, GET_ALL_RESOURCE_SUCCESS, GET_RESOURCE_FAIL, GET_RESOURCE_REQUEST, GET_RESOURCE_SUCCESS, UPDATE_RESOURCE_FAIL, UPDATE_RESOURCE_REQUEST, UPDATE_RESOURCE_RESET, UPDATE_RESOURCE_SUCCESS } from '../constants/resourceConstant'




export const getResource = (state = { resource: [] }, action) => {
  switch (action.type) {
    case GET_RESOURCE_REQUEST:
      return {
        loading: true,
      };

    case GET_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resource: action.payload.resource,
        resourceOwnerUser: action.payload.user,
      };

    case GET_RESOURCE_FAIL:
      return {
        ...state,
        loading: false,
        resource: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const resourceUpdate = (state = { resources: {} }, action) => {
  switch (action.type) {
    case CREATE_RESOURCE_REQUEST:
    case UPDATE_RESOURCE_REQUEST:
    case DELETE_RESOURCE_REQUEST:
      return {
        loading: true,
        isUpdated: false,
      };

    case CREATE_RESOURCE_SUCCESS:
    case UPDATE_RESOURCE_SUCCESS:
    case DELETE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        resources: action.payload,
      };

    case CREATE_RESOURCE_FAIL:
    case UPDATE_RESOURCE_FAIL:
    case DELETE_RESOURCE_FAIL:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        resources: null,
        error: action.payload,
      };

    case CREATE_RESOURCE_RESET:
    case UPDATE_RESOURCE_RESET:
    case DELETE_RESOURCE_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        resources: null,
        error: null,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getAllResources = (state = { students: [] }, action) => {
  switch (action.type) {
    case GET_ALL_MY_RESOURCE_REQUEST:
    case GET_ALL_RESOURCE_REQUEST:
      return {
        loading: true,
      };

    case GET_ALL_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: action.payload.resources,
        totalResources: action.payload.totalResources,
        resultPerPage: action.payload.resultPerPage,
      };

    case GET_ALL_MY_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: action.payload.resources,
        totalResources: action.payload.totalResources,
        resultPerPage: action.payload.resultPerPage,
      };




    case GET_ALL_MY_RESOURCE_FAIL:
    case GET_ALL_RESOURCE_FAIL:
      return {
        ...state,
        loading: false,
        resources: null,
        totalResources: null,
        resultPerPage: null,
        error: action.payload,

      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
