import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { FaClock, FaLink, FaSearch, FaTimes } from "react-icons/fa";
import { TbWorld } from "react-icons/tb";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getAllTracksAction } from "../../actions/monitoringAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import SidebarMonitor from "../../components/SidebarMonitor.js";
import AsignedHeader from "../../Pages/FileMangement/AsignedHeader";
import "./monitorActiveApps.css";
import Sidebar from "../../components/Sidebar.js";
import SelectMonitorUserUI from "./SelectMonitorUserUI.js";
import { getUser } from "../../utils/getUser.js";
import {  getMonitorUsersAction, clearError as userClearErr} from '../../actions/monitoringAction';




const StudentsApplication = ({ underLine, setUnderLine }) => {
    setUnderLine('tracks');



    const dispatch = useDispatch();
    const { error, tracks, resultPerPage, totalTracks, loading } = useSelector((state) => state.getTracks);
    const { user } = useSelector((state) => state.users);

    useEffect(() => {
        if (error) {
            console.log(error)
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);

    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);

    const formattedDate = today.toISOString().split('T')[0];
    const nextDate = nextDay.toISOString().split('T')[0];

    const [date1, setDate1] = useState(formattedDate);
    const [date2, setDate2] = useState(nextDate);
    const [sno, setSno] = useState('');
    const [id, setId] = useState('');
    const [CurrentPage, setCurrentPage] = useState(1)


    useEffect(() => {
        searchHandler()
    }, [sno , id,CurrentPage]);

    useEffect(() => {
        try {

            // const sno = window.localStorage.getItem('sno');
            // const id = window.localStorage.getItem('id');

            // if (sno && id) {
            //     setSno(sno)
            //     setId(id)
            // }

            console.log(sno, id)

        } catch (error) {
            console.error(error);
        }

    }, [sno, id]);
     // ==================================== get all users ====================================================

     const { error: getUserErr, users } = useSelector((state) => state.getMonitorUsers);

     useEffect(() => {
         dispatch(getMonitorUsersAction());
     }, []);
 
     useEffect(() => {
         if (getUserErr) {
             // console.log(error)
             toast.error(getUserErr)
             dispatch(userClearErr())
         }
     }, [getUserErr]);
 
     // ==================================== get all users end ====================================================
 
 

    const Navigate = useNavigate();

    useEffect(() => {
        if (user?.role !== 'admin') {
            Navigate('/dashboard');
        }
    }, [user])

    const searchHandler = () => {

        dispatch(getAllTracksAction(CurrentPage));

    }
   


    const columns = [
        { field: "id", headerName: "S No.", minWidth: 50, flex: 0.2 },


        {
            field: "status",
            headerName: "Status",
            minWidth: 100,
            flex: 0.3,
        },

        {
            field: "user",
            headerName: "User",
            minWidth: 300,
            flex: 0.3,
        },
        {
            field: "url",
            headerName: "URL",
            minWidth: 450,
            flex: 0.5,
            sortable: false,
            renderCell: (params) => {
              return (
                <div key={params.id} >
      
                  <Link to={params.row.url} className="student-icons">
                    <FaLink /> {params.row.url}
                  </Link>

                </div>
              );
            },
        },
        {
            field: "title",
            headerName: "Title",
            minWidth: 300,
            flex: 0.5,
        },

        {
            field: "date",
            headerName: "Created At",
            minWidth: 200,
            flex: 0.3,
            // cellClassName: (params) => {
            //   return (params.getValue(params.row.id_, "role"))
            // },
        },
        
    ];

    const rows = [];

    tracks &&
        tracks.forEach((item, i) => {
            const user = getUser(item?.userId, users)
            let date = new Date(item?.createAt);


            rows.push({
                id: ((CurrentPage - 1) * resultPerPage) + i + 1 ,
                user: `${user?.name} - ${user?.email}`,

                id_: item._id,
                status: item.status,
                url: item.url,
                title: item.title,
                date: date.toString().split('G')[0]
            });
        });

    // modal code 


    return (
        <div className="dashboardMain">
            <MetaData title={"SubmitApp Screenshot Tracking "} />

            <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
                <SidebarMonitor underLine={underLine} />
            </div>


            <div className="dashboardContainer">
                <AsignedHeader heading={"SubmitApp Screenshot Tracking "} />
                {/* <SelectMonitorUserUI setId={setId} setSno={setSno} /> */}
                {
                    loading ? <Loading /> : (
                        <>
                            <div className="D-ButtonLayer">

                                {/* <div className="BlRow1">
                                    <div className="BlItem BlItem4">
                                        <div>
                                            <p>Log Activity</p>
                                            <p>{totalTracks}</p>
                                        </div>
                                        <div className="">
                                            <FaClock />
                                        </div>
                                    </div>

                                    <div className="student-date-box">
                                    <p style={{paddingBottom:'7px'}}>Both Date Required <b style={{color:'red'}}>*</b></p>

                                        <form action="" onSubmit={searchByHandler}>
                                            <input type="date" placeholder="Select Date" className="docBtn1" value={date1} onChange={e => setDate1(e.target.value)} />
                                            to
                                            <input type="date" placeholder="Select Date" className="docBtn1" value={date2} onChange={e => setDate2(e.target.value)} />

                                            <button type="submit" className="docBtn1" onClick={searchByHandler} value={'find'} ><FaSearch /></button>
                                        </form>
                                    </div>
                                </div> */}

                            </div>

                            <div className="usersContainer">
                                {
                                    rows.length !== 0 ? (<DataGrid
                                        rows={rows}
                                        columns={columns}
                                        pageSize={20}
                                        disableSelectionOnClick
                                        className="usersTable"
                                        // autoHeight
                                        autoWidth
                                        autoHeight

                                        sx={{
                                            fontSize: 14,
                                            backgroundColor: 'white',
                                            boxShadow: 0,
                                            fontFamily: 'Roboto',
                                            color: 'black',
                                            //   headerColor: 'black',
                                            padding: 2,
                                            '& .MuiDataGrid-cell:hover': {
                                                color: 'primary.main',
                                            },

                                        }}
                                    />) : <NoDataFound />
                                }


                            </div>

                            {
                                totalTracks > resultPerPage &&

                                <div className="paginationContainer">
                                    <Pagination
                                        activePage={CurrentPage}
                                        itemsCountPerPage={resultPerPage}
                                        totalItemsCount={totalTracks}
                                        onChange={e => setCurrentPage(e)}

                                        className="paginationBox"

                                        // prevPageText="<"
                                        firstPageText="1st"
                                        lastPageText="Last"
                                        // nextPageText=">"
                                        itemClass='page-item'
                                        linkClass='page-link'
                                        activeClass='pageItemActive'
                                        activeLinkClass='pageLinkActive'
                                    />
                                </div>
                            }

                        </>
                    )
                }
            </div>


        </div>
    );
};

export default StudentsApplication;
