import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearError, createCommentAction, deleteCommentAction, getCommentsAction, updateCommentAction, clearError as userClearError } from '../actions/commentAction';
import { useParams } from 'react-router-dom';
import { CREATE_COMMENT_RESET } from '../constants/commentConstant';
import './comment.css'
import { getMonitorUsersAction } from '../actions/monitoringAction';
import { getCommentUser } from '../utils/getUser';
import { FaEdit, FaPlus, FaUser } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { TbMoodSad } from 'react-icons/tb';
import { getDateTime, getDateTimeIST, isDeleteComment, isEditComment } from '../utils/getDates';

const commentType = 'student-application'




const CommentApp = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [editComment, setEditComment] = useState(null);
  const [editText, setEditText] = useState('');
  const [pageNo, setPageNo] = useState(1);

  const { user } = useSelector((state) => state.users);
  const { error: getUserErr, users } = useSelector((state) => state.getMonitorUsers);
  const { error, isUpdated, message, loading } = useSelector((state) => state.updateComment);
  const { error: comErr, resultPerPage, totalComments, comments: allComments, loading: comLoading } = useSelector((state) => state.getComments);

  const { id } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
    if (getUserErr) {
      toast.error(getUserErr);
      dispatch(userClearError());
    }
    if (comErr) {
      toast.error(comErr);
      dispatch(userClearError());
    }
    if (isUpdated) {
      toast.success(message);
      dispatch(getCommentsAction(id, pageNo, commentType));

      dispatch({ type: CREATE_COMMENT_RESET });
    }
  }, [error, isUpdated]);

  useEffect(() => {
    if (id) {
      dispatch(getCommentsAction(id, pageNo, commentType));
    }
  }, [id, pageNo]);

  useEffect(() => {
    dispatch(getMonitorUsersAction(id, pageNo, commentType));
  }, []);

  console.log(allComments, resultPerPage, totalComments);

  // Create Comment
  const handleAddComment = () => {
    if (newComment.trim() !== '') {
      dispatch(createCommentAction({ userId: user?._id, studentId: id, comment: newComment, commentType }))

      setNewComment('');
    }
  };

  // Update Comment
  const handleUpdateComment = (id) => {

    dispatch(updateCommentAction(id, { comment: editText }))


    setEditComment(null);
    setEditText('');
  };

  // Delete Comment
  const handleDeleteComment = (id) => {
    dispatch(deleteCommentAction(id))


  };

  const loadMoreHandle = () => {
    setPageNo(pageNo + 1)
  }

  return (
    <div className='commentContainer'>
      <h3>Comments </h3>

      {/* Add Comment Section */}
      <div className='addCommentBox'>
        <input
          type="text"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment..."
          className='inputField'
        />
        <button onClick={handleAddComment} className='docBtn'>Add</button>
      </div>

      {/* Comments List */}
      {
        loading ? 'Loading...' :
          <div className='commentsBox'>
            {allComments?.map((comment) => {
              const me = getCommentUser(comment?.userId, users);
              return (
                <div key={comment._id} className='commentItem'>
                  {editComment === comment._id ? (
                    <div className='updateBox'>
                      <input
                        className='inputField'
                        type="text"
                        value={editText}
                        onChange={(e) => setEditText(e.target.value)}
                      />
                      <button className='docBtn saveBtn' onClick={() => handleUpdateComment(comment._id)}>
                        Save
                      </button>
                      <button className='docBtn canBtn' onClick={() => setEditComment(null)}>Cancel</button>
                    </div>
                  ) : (
                    <div className='commentItem'>
                      <div className='profileIcon'>
                        <FaUser />
                      </div>

                      <div className='commentField'>
                        <p>{me?.name} - {me?.email} - {getDateTimeIST(comment.createAt)} </p>

                        <p> {comment.deleteAt !== null ? <p className='delCom'><TbMoodSad/> Comment Deleted - {getDateTimeIST(comment?.deleteAt)} </p> : comment.comment}


                          {
                            (user?._id === comment.userId && comment.deleteAt === null )&&
                            <div className='btns'>
                              {
                                isEditComment(comment.createAt) &&
                                <button className='editBtn1' onClick={() => { setEditComment(comment._id); setEditText(comment.comment) }}>
                                <FaEdit />
                              </button>
                              }
                              {
                                isDeleteComment(comment.createAt) &&

                                <button className='delBtn' onClick={() => handleDeleteComment(comment._id)}>
                                <MdDelete />
                              </button>
                              }
                            </div>
                          }

                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )
            }

            )}
          </div>
      }

      <div className='loadMoreBox'>

        {
          resultPerPage < totalComments &&
          <button className='loadMoreBtn' onClick={loadMoreHandle}> <FaPlus/> Load More comments</button>
        }


      </div>

    </div>
  );
};

export default CommentApp;
