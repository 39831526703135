import React, { useEffect, useState } from "react";
import { FaDownload, FaEdit, FaGoogleDrive, FaLink } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import Sidebar from "../../components/Sidebar";
import { clearError, getUniversityAction } from "../../actions/universityAction";
// import "./StudentDetails.css";



const StudentDetails = () => {
    const dispatch = useDispatch();
    const { error, university, loading } = useSelector((state) => state.getUniversity);
    const { user } = useSelector((state) => state.users);

    const { id } = useParams();

    let date = new Date(university?.createAt)
    const date_ = date.toString().split('G')[0]
    // console.log(university?.createAt , date_)
    let update_date = new Date(university?.updatedAt)
    const update_date_ = update_date.toString().split('G')[0]

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
            // setSelectedMultiFile([])
        }

       
    }, [error]);

    useEffect(() => {
        if (id) {
            dispatch(getUniversityAction(id));
        }
    }, [id])
    


    const arrays = ["png", "jpg", "jpeg"]


    // ===================================================================


    const [isDownload, setIsDownload] = useState(false)
    const downloadFile = async (url, fileName) => {
        setIsDownload(true)
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            setIsDownload(false)

            // Ensure the file type is supported (pdf, img, doc, docs)
            // const supportedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

            // if (!supportedTypes.includes(blob.type)) {
            //   throw new Error('Unsupported file type.');
            // }

            // Create a URL for the blob and trigger download
            const blobUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release memory after download
            window.URL.revokeObjectURL(blobUrl);

        } catch (error) {
            console.error('Error downloading the file:', error);
            setIsDownload(false)

        }
    };

    console.log(university)
    return (
        <div className="dashboardMain">
            <MetaData title={`Application - ${university?.Name}`} />

            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentDetailsBox">
                        <div className="studentUnderBox">
                            <div className="su-topHeader">
                                <h3>
                                    {university?.name} <br />
                                    Last Update :{" "}
                                    {university?.updatedAt === null ? "No Update" : update_date_}
                                </h3>

                                {/* <Link to={`/university/update/${id}`} className="editBtn">Edit <FaEdit /> </Link> */}
                            </div>


                            <div className="st-d-item">
                                <div>
                                    <p>
                                        <b>University Name :</b>
                                    </p>
                                    <p>{university?.name}</p>
                                </div>
                            </div>

                            <div className="st-d-item">
                                <div>
                                    <p>
                                        <b>Country :</b>
                                    </p>
                                    <p>{university?.country}</p>
                                </div>
                            </div>


                            <div className="st-d-item">

                                <div>
                                    <p>
                                        <b>University Name:</b>
                                    </p>
                                    <p>{university?.name}</p>
                                </div>
                            </div>

                            <div className="st-d-item">
                                <div>
                                    <p>
                                        {" "}
                                        <b>Create At:</b>
                                    </p>
                                    <p>{date_}</p>

                                </div>
                            </div>




                            {
                                <>
                                    <div className="driveBox">
                                        {
                                            university?.appSubmitLink &&
                                            <a href={university?.appSubmitLink} target="_black" className="driveLinkBtn"> <FaLink /> App Submit Link</a>
                                        }
                                    </div>

                                    <div className="driveBox">
                                        {
                                            university?.courseLink &&
                                            <a href={university?.courseLink} target="_black" className="driveLinkBtn"> <FaLink /> Course Link</a>
                                        }
                                    </div>
                                </>
                            }

                            {/* images / documents  */}

                            <h3 className="doc_heading">Documents</h3>
                            <div className="st-document-item">


                                {university?.files &&
                                    <>
                                        {
                                            university.files.map((item) => (
                                                <div className="doc-item">
                                                    <div>
                                                        <p>
                                                            <b></b> {item.doc_name}
                                                        </p>
                                                        {
                                                            isDownload ? '...' :
                                                                <button className="doc-download" onClick={e => downloadFile((item.url).split(':')[0] === 'https' ? item.url : 'https://gleam-portal-storage.blr1.cdn.digitaloceanspaces.com/' + item?.key, item.doc_name)}>
                                                                    <FaDownload />
                                                                </button>
                                                        }
                                                        {/* <a className="doc-download" target="_black" href={'https://gleam-portal-storage.blr1.cdn.digitaloceanspaces.com/'+item?.key}>
                              <FaEye />
                            </a> */}
                                                    </div>

                                                    <div>
                                                        {/* {
                              arrays.includes(item.url.split(".")[2])
                            } */}
                                                        {
                                                            // arrays.includes(item.url.split(".")[2]) ? (<img src={item.url} alt="file" />) : (<img src={'https://cdn-icons-png.flaticon.com/512/2246/2246713.png'} alt="file" />)
                                                            item.doc_type.split("/")[0] === 'image' ? (<img src={item?.url} alt="file" />) : (<img src={require('../../images/file.png')} alt="file" />)
                                                        }

                                                    </div>
                                                </div>
                                            )
                                            )
                                        }
                                    </>
                                }

                            </div>

                        </div>


                    </div>


                </div>
            )}
        </div>
    );
};

export default StudentDetails;
