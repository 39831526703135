let host = "";
const port = window.location.hostname

if (port === 'portal.saanwariyaeduconsultant.com') {
    host = "http://portal.saanwariyaeduconsultant.com"
}
else if (port === 'localhost') {
    host = "http://localhost:5149";
}
else if (port === '157.245.99.110') {
    host = "http://157.245.99.110:5149";
}
else if (port === '62.72.31.157') {
    host = "http://62.72.31.157:5149";
}
else if (port === 'api.saanwariyaeduconsultant.com') {
    host = "http://api.saanwariyaeduconsultant.com"
}


//  host = "http://157.245.99.110:5409"
//   host = "http://portal.saanwariyaeduconsultants.com"
//  host = "http://localhost:5409";




export default host


// console.log(port)

