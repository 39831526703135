export const team_name = [
    {
        id: '0',
        name: 'None'
    },

    {
        id: '1',
        name: 'UK'
    },
    {
        id: '2',
        name: 'USA'
    },
    {
        id: '3',
        name: 'Canada'
    },
    {
        id: '4',
        name: 'Australia'
    },
    {
        id: '5',
        name: 'ROW'
    },

]

export const status = [
    {
        id: '0',
        name: 'New Application'
    },

    {
        id: '1',
        name: 'New Lead'
    },
    {
        id: '5',
        name: 'Processing'
    },
    {
        id: '6',
        name: 'Pending'
    },
    {
        id: '3',
        name: 'Approved'
    },
    {
        id: '4',
        name: 'Rejected'
    },
  

]


export const getTeamName = (id)=>{
    const item = team_name.find(item => item.id === id) 

    if(item) return item?.name  

    return 'Invalid Id';
}
export const getStatus = (id)=>{
    const item = status.find(item => item.id === id) 

    if(item) return item?.name  

    return 'Invalid Id';
}