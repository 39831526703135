import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearError } from '../../actions/userAction';
import { getMonitorUsersAction } from '../../actions/monitoringAction';
import './selectMonitor.css'

const SelectMonitorUserUI = ({setSno,setId}) => {

    const { error, users, resultPerPage, totalUsers, loading } = useSelector((state) => state.getMonitorUsers);
    const { user ,isAuthenticated} = useSelector((state) => state.users);

    const dispatch = useDispatch();
    const Navigate = useNavigate();

    const [selUser, setSelUser] = useState(null);
    const [selDevice, setSelDevice] = useState(null);
    const [device, setDevice] = useState(null);



    useEffect(() => {
        if (error) {
            toast.error(error)
            dispatch(clearError())
        }
    }, [error]);

    useEffect(() => {
        dispatch(getMonitorUsersAction())
    }, [])

    useEffect(() => {
        if(isAuthenticated)
        if (user?.role !== 'admin') {
            Navigate('/dashboard');
        }
    }, [user])


    useEffect(() => {

        // console.log(selUser)
        setDevice(null)


        if (selUser?.monitorDevices?.length !== 0) {
            setDevice(selUser?.monitorDevices);
            setSelDevice(selUser?.monitorDevices[0]);
        }
        else {
            toast.error(`${selUser?.name} not install monitoring software.`);
        }

    }, [selUser])

    useEffect(() => {
        // console.log(selDevice);

        // if (selDevice?.serialNumber && selUser?._id) {
        //     window.localStorage.setItem('sno', selDevice?.serialNumber);
        //     window.localStorage.setItem('id', selUser?._id);
        // }

        setId(selUser?._id);
        setSno(selDevice?.serialNumber)

        // console.log(selDevice?.serialNumber, selUser?._id)

    }, [selDevice,selUser]);



    const handleSelectUser = (user) => {
            try {
                setSelUser(JSON.parse(user))
            } catch (error) {

            }
    }





    return (
        <div className='monitorUserContainer'>

            <div>
                <div className="updateItemBox">
                 
                    <div className="updateInputItem">
                        <p className='textWhite'>Select User Required <b style={{color:'red'}}>*</b></p>
                        <select name="" id="" placeholder="value"
                            value={JSON.stringify(selUser)}
                            onChange={e => handleSelectUser(e.target.value)}>
                            <option value={''}>Select</option>

                            {
                                users?.map((item) => (
                                    <option value={JSON.stringify(item)}>{item.name} - {item.email} - {item.monitorLogin ? 'Yes' : 'No'}</option>
                                ))
                            }
                        </select>
                        {/* <p>{selUser?.email}</p> */}

                    </div>


                    {
                        device?.length !== 0 &&

                        <div className="updateInputItem">
                            <p className='textWhite'>Select Device Required <b style={{color:'red'}}>*</b></p>
                            <select name="" id="" placeholder="Device"
                                value={JSON.stringify(selDevice)}
                                onChange={e => setSelDevice(JSON.parse(e.target.value))}>
                                {
                                    device?.map((item) => (
                                        <option value={JSON.stringify(item)}>{item.manufacturer}</option>
                                    ))
                                }
                            </select>
                            {/* <p>{selDevice?.manufacturer}</p> */}
                        </div>
                    }

                </div>
            </div>

        </div>
    )
}

export default SelectMonitorUserUI