export const CREATE_COMMENT_REQUEST = "CREATE_COMMENT_REQUEST"
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS"
export const CREATE_COMMENT_FAIL = "CREATE_COMMENT_FAIL"
export const CREATE_COMMENT_RESET = "CREATE_COMMENT_RESET"

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST"
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS"
export const UPDATE_COMMENT_FAIL = "UPDATE_COMMENT_FAIL"
export const UPDATE_COMMENT_RESET = "UPDATE_COMMENT_RESET"

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST"
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS"
export const DELETE_COMMENT_FAIL = "DELETE_COMMENT_FAIL"
export const DELETE_COMMENT_RESET = "DELETE_COMMENT_RESET"

export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_FAIL = "GET_COMMENTS_FAIL"


export const CLEAR_ERROR = "CLEAR_ERROR"

