import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Loading from "../../components/Loading/loading";
import { useDispatch, useSelector } from "react-redux";
import "./StudentUpdate.css";
import { clearError, createStudent, getStudent, studentdUpdate } from "../../actions/fileAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { STUDENT_UPDATE_RESET } from "../../constants/fileConstant";
import { team_name, status as applicationStatus } from "../../components/student";
import DocumentUploadUi from '../../components/DocumentUploadUi.jsx'

const StudentUpdate = () => {
    const dispatch = useDispatch();

    const { error, isUpdated, loading } = useSelector(
        (state) => state.updateStudent
    );

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.users);


    const [fields, setFields] = useState([
        // { name: "", file: null, fileType: "" }
    ]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const s = queryParams.get('s');


    const [studentData, setStudentData] = useState({
        name: '', email: '', phone: '', country: '', status: '0', Team_Name: '', comments: '',
        University_Name: '', Lead_from_State: '', Agent_Name: '', Intake: '', Date_Of_Birth: '',
        Region: '', Application_Id: '', Student_Id: '', Course: '', File_Drive_Link: '', visaRejected: ''
    })

    const {
        name, email, phone, country, status, Team_Name, comments, visaRejected,
        University_Name, Agent_Name, Intake, Date_Of_Birth, Lead_from_State,
        Region, Course, File_Drive_Link
    } = studentData;


    const studentCreateHandler = async (e) => {
        e.preventDefault();

        let data = {
            Name: name, Email: email, Country: country, Phone: phone,
            University_Name, Team_Name, Agent_Name, Intake, Date_Of_Birth,
            Region, Course, Lead_from_State, comments, File_Drive_Link, visaRejected,

            status: (user?.role !== 'agent') ? fields.length !== 0 ? '0' : '1' : '0'
        };

        // console.log(data)

        dispatch(createStudent(data, fields));
    };



    const studentDataChangeHandler = e => {
        setStudentData({ ...studentData, [e.target.name]: e.target.value })
    }




    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
            // setSelectedMultiFile([])
        }
        if (isUpdated) {
            toast.success("Application has created Successfully");
            // if(user?.role==='counsellor'){
            // }else{
            // }
            dispatch({ type: STUDENT_UPDATE_RESET });
            navigate(-1);
        }
    }, [error, isUpdated, dispatch]);

    return (
        <div className="dashboardMain">
            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentUpdateBox">
                        <form action="" onSubmit={studentCreateHandler}>
                            <h3>Create New {s === '1' ? "Lead" : "Application"} </h3>


                            <>
                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Name</p>
                                        <input
                                            type="text"
                                            required

                                            value={name}
                                            name="name" placeholder="value"
                                            onChange={studentDataChangeHandler} />
                                    </div>

                                    <div className="updateInputItem">
                                        <p>Email</p>
                                        <input
                                            type="text"
                                            required

                                            value={email}
                                            name="email" placeholder="value"
                                            onChange={studentDataChangeHandler} />
                                    </div>
                                </div>



                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Phone no.</p>
                                        <input
                                            type="text"
                                            required

                                            value={phone}
                                            name="phone" placeholder="value"
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>

                                    <div className="updateInputItem">
                                        <p>Country </p>
                                        <input
                                            type="text"
                                            value={country}
                                            name="country" placeholder="value"
                                            onChange={studentDataChangeHandler}

                                        />
                                    </div>
                                </div>





                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Course</p>
                                        <input
                                            name="Course"
                                            type="text" placeholder="value"
                                            value={Course}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>

                                    <div className="updateInputItem">
                                        <p>Intake</p>
                                        <input
                                            name="Intake" placeholder="value"
                                            type="text"
                                            value={Intake}
                                            onChange={studentDataChangeHandler}
                                        />

                                    </div>
                                </div>


                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Date Of Birth</p>
                                        <input
                                            name="Date_Of_Birth" placeholder="value"
                                            type="text"
                                            value={Date_Of_Birth}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                </div>




                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>University Name</p>
                                        <input
                                            name="University_Name"
                                            type="text" placeholder="value"
                                            value={University_Name}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                    {/* <div className="updateInputItem">
                                        <p>Lead From State</p>
                                        <input
                                            name="Lead_from_State" placeholder="value"
                                            type="text"
                                            value={Lead_from_State}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div> */}

                                </div>

                                {
                                    user?.role !== 'agent' &&
                                    <div className="updateItemBox">
                                        <div className="updateInputItem">
                                            <p>Google Drive Link</p>
                                            <input
                                                name="File_Drive_Link" placeholder="value"
                                                type="text"
                                                value={File_Drive_Link}
                                                onChange={studentDataChangeHandler}
                                            />
                                        </div>
                                    </div>
                                }
                                <br />

                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Team Name</p>
                                        <select name="Team_Name" id="" placeholder="value"
                                            value={Team_Name}
                                            onChange={studentDataChangeHandler}>
                                            {
                                                team_name?.map((item) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </>

                            <br />

                            <div className="updateItemBox">
                                <div className="updateInputItem">
                                    <p>If Past Visa Rejected ?</p>
                                    <textarea placeholder="value" name="visaRejected" className="visaInput" value={visaRejected} onChange={studentDataChangeHandler} id=""></textarea>
                                </div>
                            </div>
                            <br />

                            <div className="updateItemBox">
                                <div className="updateInputItem">
                                    <p>Comments</p>
                                    <textarea placeholder="Your Name : Your Comments..." name="comments" className="commentsInput" value={comments} onChange={studentDataChangeHandler} id=""></textarea>
                                </div>
                            </div>
                            {/* <div className="updateItemBox">

                                <div className="seletItem">
                                    <p>Please Check Status</p>
                                    <select name="status" id=""
                                        value={status}
                                        onChange={studentDataChangeHandler}>
                                                <option value={''}>none</option>

                                        {
                                            applicationStatus?.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }


                                    </select>

                                </div>
                            </div> */}

                            <DocumentUploadUi setFields={setFields} fields={fields} />



                            <div className="submitItem">
                                <input type="submit" value={s === '1' ? "Create Lead" : "Create Application"} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentUpdate;
