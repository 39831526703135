import React, { useEffect, useState } from "react";
// import "./FileManagement.css";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";
import { FaPencilAlt, FaSearch, FaUniversity, FaUserAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getAllUniversityAction } from "../../actions/universityAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import Sidebar from "../../components/Sidebar";
import AsignedHeader from "../../Pages/FileMangement/AsignedHeader";




const StudentsApplication = ({ underLine, setUnderLine }) => {
  setUnderLine('universities')

  const dispatch = useDispatch();
  const { error, universities, resultPerPage, totalUniversity, loading } = useSelector((state) => state.getAllUniversity);
  const { user,isAuthenticated } = useSelector((state) => state.users);

  useEffect(() => {
    if (error) {
      console.log(error)
      toast.error(error)
      dispatch(clearError())
    }
  }, [error]);

  const [searchWord, setSearchWord] = useState('');
  const [CurrentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    searchHandler()
  }, [CurrentPage]);

  const Navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated)
      if (user?.role !== 'admin' && user?.role !== 'agent' && user?.role !== 'counsellor' && user?.role !== 'processor' && user?.role !== 'supervisor') {
        Navigate('/dashboard');
      }
  }, [user])

  const searchHandler = () => {

    dispatch(getAllUniversityAction(CurrentPage, searchWord))
  }
  const searchByHandler = () => {
    setCurrentPage(1)
    dispatch(getAllUniversityAction(CurrentPage, searchWord))
  }


  const columns = [
    { field: "id", headerName: "S No.", minWidth: 50, flex: 0.2 },


    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      flex: 0.5,
    },


    {
      field: "country",
      headerName: "Country",
      minWidth: 150,
      flex: 0.5,
    },


    {
      field: "createAt",
      headerName: "Create At",
      minWidth: 200,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.row.id_, "role"))
      // },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 220,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        console.log("dsf", params.id);
        return (
          <div key={params.id} >

            <Link to={`/university/${params.row.id_}`} className="student-icons">
              <AiFillEye />
            </Link>


          </div>
        );
      },
    },
  ];

  const rows = [];

  universities &&
    universities.forEach((item, i) => {
      let date = new Date(item?.createAt)

      rows.push({
        id: i + 1,
        id_: item._id,
        name: item.name,
        country: item.country,
        createAt: date.toString().split('G')[0]
        ,
      });
    });

  // modal code 


  return (
    <div className="dashboardMain">
      <MetaData title={"Application Management"} />

      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />

      </div>
      {
        loading ? <Loading /> : (

          <div className="dashboardContainer">
            <AsignedHeader heading={"University Management"} />


            <div className="D-ButtonLayer">

              <div className="BlRow1">
                <div className="BlItem BlItem4">
                  <div>
                    <p>Total Universities</p>
                    <p>{totalUniversity && totalUniversity}</p>
                  </div>
                  <div className="">
                    <FaUniversity />
                  </div>
                </div>

                <div className="student-search-box">
                  <form action="" onSubmit={searchByHandler}>
                    <input type="text" placeholder="Search something here..." value={searchWord} onChange={e => setSearchWord(e.target.value)} />
                    <button type="submit" className="submitBtn" onClick={searchByHandler} value={'find'} ><FaSearch /></button>

                  </form>
                </div>
              </div>

            </div>

            <div className="usersContainer">

              {
                rows.length !== 0 ? (<DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={20}
                  disableSelectionOnClick
                  className="usersTable"
                  // autoHeight
                  autoWidth
                  autoHeight


                  sx={{
                    fontSize: 14,
                    backgroundColor: 'white',
                    boxShadow: 0,
                    fontFamily: 'Roboto',
                    color: 'black',
                    //   headerColor: 'black',
                    padding: 2,
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },

                  }}
                />) : <NoDataFound />
              }


            </div>

            {
              totalUniversity > resultPerPage &&

              <div className="paginationContainer">
                <Pagination
                  activePage={CurrentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={totalUniversity}
                  onChange={e => setCurrentPage(e)}

                  className="paginationBox"

                  // prevPageText="<"
                  firstPageText="1st"
                  lastPageText="Last"
                  // nextPageText=">"
                  itemClass='page-item'
                  linkClass='page-link'
                  activeClass='pageItemActive'
                  activeLinkClass='pageLinkActive'
                />
              </div>
            }

          </div>
        )
      }


    </div>
  );
};

export default StudentsApplication;
