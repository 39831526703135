import React, { useEffect, useState } from "react";
// import "./FileManagement.css";
import { DataGrid } from "@mui/x-data-grid";
import { AiFillEye } from "react-icons/ai";
import { FaLink, FaPencilAlt, FaSearch, FaUniversity, FaUserAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getAllResourceAction } from "../../actions/resourceAction";
import Loading from "../../components/Loading/loading";
import MetaData from "../../components/MetaData";
import NoDataFound from "../../components/NoDataFound";
import Sidebar from "../../components/Sidebar";
import AsignedHeader from "../../Pages/FileMangement/AsignedHeader";
import { getDateTime } from "../../utils/getDates";
import { getUser } from "../../utils/getUser";
import {clearError as userClearErr, getMonitorUsersAction } from "../../actions/monitoringAction";




const AllResource = ({ underLine, setUnderLine }) => {
  setUnderLine('resources')

  const dispatch = useDispatch();
  const { error, resources, resultPerPage, totalResources, loading } = useSelector((state) => state.getAllResources);
  const { user,isAuthenticated } = useSelector((state) => state.users);
  const { error: getUserErr, users } = useSelector((state) => state.getMonitorUsers);


    useEffect(() => {
        dispatch(getMonitorUsersAction());
    }, []);

  useEffect(() => {
    if (error) {
      console.log(error)
      toast.error(error)
      dispatch(clearError())
    }
    if (getUserErr) {
      // console.log(error)
      toast.error(getUserErr)
      dispatch(userClearErr())
  }
  }, [error,getUserErr]);

  const [searchWord, setSearchWord] = useState('');
  const [CurrentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    searchHandler()
  }, [CurrentPage]);

  const Navigate = useNavigate();

  useEffect(() => {
    if(isAuthenticated)
    if (user?.role !== 'admin' && user?.role !== 'agent' && user?.role !== 'counsellor' && user?.role !== 'processor' && user?.role !== 'supervisor') {
      Navigate('/dashboard');
    }
  }, [user])

  const searchHandler = () => {

    dispatch(getAllResourceAction(CurrentPage, searchWord))
  }
  const searchByHandler = () => {
    setCurrentPage(1)
    dispatch(getAllResourceAction(CurrentPage, searchWord))
  }


  const columns = [
    { field: "id", headerName: "S No.", minWidth: 50, flex: 0.2 },

    {
      field: "sendBy",
      headerName: "Send By",
      minWidth: 200,
      flex: 0.8,
  },

    {
      field: "message",
      headerName: "Message",
      minWidth: 100,
      flex: 0.5,
    },

    {
      field: "isFile",
      headerName: "Available Files",
      minWidth: 200,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.row.id_, "role"))
      // },
  },


    {
      field: "createAt",
      headerName: "Create At",
      minWidth: 200,
      flex: 0.5,
      // cellClassName: (params) => {
      //   return (params.getValue(params.row.id_, "role"))
      // },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "number",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        console.log("dsf", params.id);
        return (
          <div key={params.id} >

            <Link to={`/resource/${params.row.id_}`} className="student-icons">
              <AiFillEye />
            </Link>


            <a href={params.row.link} target="_black" className="student-icons">
              <FaLink />
            </a>


          </div>
        );
      },
    },
  ];

  const rows = [];

  resources &&
    resources.forEach((item, i) => {
      const user = getUser(item?.createAtUserId, users)

      rows.push({
        id: i + 1,
        id_: item._id,
        sendBy: `${user?.name} - ${user?.email}`,
        isFile: item.documentsFiles.length !== 0 ? "Yes" : "No",
        link: item.link,
        message: item.textMessage,
        link: item.link,
        createAt: getDateTime(item?.createdAt)
        ,
      });
    });

  // modal code 


  return (
    <div className="dashboardMain">
      <MetaData title={"Application Management"} />

      <div className={window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"}>
        <Sidebar underLine={underLine} />

      </div>
      {
        loading ? <Loading /> : (

          <div className="dashboardContainer">
            <AsignedHeader heading={"Resource Management"} />


            <div className="D-ButtonLayer">

              <div className="BlRow1">
                <div className="BlItem BlItem4">
                  <div>
                    <p>Total Resources</p>
                    <p>{totalResources && totalResources}</p>
                  </div>
                  <div className="">
                    <FaUniversity />
                  </div>
                </div>

                {/* <div className="student-search-box">
                  <form action="" onSubmit={searchByHandler}>
                    <input type="text" placeholder="Search something here..." value={searchWord} onChange={e => setSearchWord(e.target.value)} />
                    <button type="submit" className="submitBtn" onClick={searchByHandler} value={'find'} ><FaSearch /></button>

                  </form>
                </div> */}
              </div>

            </div>

            <div className="usersContainer">

              {
                rows.length !== 0 ? (<DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={20}
                  disableSelectionOnClick
                  className="usersTable"
                  // autoHeight
                  autoWidth
                  autoHeight


                  sx={{
                    fontSize: 14,
                    backgroundColor: 'white',
                    boxShadow: 0,
                    fontFamily: 'Roboto',
                    color: 'black',
                    //   headerColor: 'black',
                    padding: 2,
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },

                  }}
                />) : <NoDataFound />
              }


            </div>

            {
              totalResources > resultPerPage &&

              <div className="paginationContainer">
                <Pagination
                  activePage={CurrentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={totalResources}
                  onChange={e => setCurrentPage(e)}

                  className="paginationBox"

                  // prevPageText="<"
                  firstPageText="1st"
                  lastPageText="Last"
                  // nextPageText=">"
                  itemClass='page-item'
                  linkClass='page-link'
                  activeClass='pageItemActive'
                  activeLinkClass='pageLinkActive'
                />
              </div>
            }

          </div>
        )
      }


    </div>
  );
};

export default AllResource;
