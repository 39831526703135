import './loading.css'

import React from 'react'

const Loading = () => {
  return (
    <div className='loading'>
        {/* <p>Gleam</p> */}
      {/* <MetaData title="Loading..." /> */}
        <div></div>
    </div>
  )
}

export default Loading