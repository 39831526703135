import axios from "axios";

import host from "../utils/var";
import { CLEAR_ERROR, CREATE_SCREEN_TRACKS_FAIL, CREATE_SCREEN_TRACKS_REQUEST, CREATE_SCREEN_TRACKS_SUCCESS, GET_APPS_FAIL, GET_APPS_REQUEST, GET_APPS_SUCCESS, GET_LOGS_FAIL, GET_LOGS_REQUEST, GET_LOGS_SUCCESS, GET_MONITOR_USER_FAIL, GET_MONITOR_USER_REQUEST, GET_MONITOR_USER_SUCCESS, GET_MONITOR_USERS_FAIL, GET_MONITOR_USERS_REQUEST, GET_MONITOR_USERS_SUCCESS, GET_RECORDINGS_FAIL, GET_RECORDINGS_REQUEST, GET_RECORDINGS_SUCCESS, GET_SCREEN_TRACK_FAIL, GET_SCREEN_TRACK_REQUEST, GET_SCREEN_TRACK_SUCCESS, GET_SCREEN_TRACKS_FAIL, GET_SCREEN_TRACKS_REQUEST, GET_SCREEN_TRACKS_SUCCESS, GET_SCREENSHOTS_FAIL, GET_SCREENSHOTS_REQUEST, GET_SCREENSHOTS_SUCCESS } from "../constants/monitoringConstant";
// const monitorHost = "http://localhost:5120";
// const monitorHost = "http://195.35.6.133:5120";
const monitorHost = "https://attendance.submitapp.in";


export const getMonitorUsersAction = () => async (dispatch) => {
    try {
        dispatch({ type: GET_MONITOR_USERS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${host}/api/v1/get-monitor-users`, config);
        dispatch({ type: GET_MONITOR_USERS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_MONITOR_USERS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getAllTracksAction = (page) => async (dispatch) => {
    try {
        dispatch({ type: GET_SCREEN_TRACKS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${monitorHost}/api/get-track-user-screenshot?page=${page}`, config);
        dispatch({ type: GET_SCREEN_TRACKS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_SCREEN_TRACKS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getTrackAction = (_id) => async (dispatch) => {
    try {
        dispatch({ type: GET_SCREEN_TRACK_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${monitorHost}/api/get-one-track-user-screenshot/${_id}`, config);
        dispatch({ type: GET_SCREEN_TRACK_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_SCREEN_TRACK_FAIL,
            payload: error?.response?.data.message,
        });
    }
};
export const createTrackAction = (details) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_SCREEN_TRACKS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.post(`${monitorHost}/api/track-user-screenshot`,details, config);
        dispatch({ type: CREATE_SCREEN_TRACKS_SUCCESS, payload: data.data });

    } catch (error) {
        console.log( error?.response)
        dispatch({
            type: CREATE_SCREEN_TRACKS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getMonitorUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: GET_MONITOR_USER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${host}/api/v1/get-user-devices/${id}`, config);

        dispatch({ type: GET_MONITOR_USER_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_MONITOR_USER_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getApps = ({ sno, id, date, date2, page }) => async (dispatch) => {
    try {
        dispatch({ type: GET_APPS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${monitorHost}/api/get-all-active-app?sno=${sno}&id=${id}&page=${page}&date=${date}&date2=${date2}`, config);

        dispatch({ type: GET_APPS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_APPS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getScreenshots = ({ sno, id, date, date2, page }) => async (dispatch) => {
    try {
        dispatch({ type: GET_SCREENSHOTS_REQUEST });

        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${monitorHost}/api/get-all-screenshot?sno=${sno}&id=${id}&page=${page}&date=${date}&date2=${date2}`, config);

        dispatch({ type: GET_SCREENSHOTS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_SCREENSHOTS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getRecordings = ({ sno, id, date, date2, page }) => async (dispatch) => {
    try {
        dispatch({ type: GET_RECORDINGS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${monitorHost}/api/get-all-recording?sno=${sno}&id=${id}&page=${page}&date=${date}&date2=${date2}`, config);

        dispatch({ type: GET_RECORDINGS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_RECORDINGS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};

export const getLogs = ({ sno, id, date, date2, page }) => async (dispatch) => {
    try {
        dispatch({ type: GET_LOGS_REQUEST });
        const config = { headers: { "Content-Type": "application/json" }, withCredentials: true };

        const data = await axios.get(`${monitorHost}/api/get-all-log-in-out?sno=${sno}&id=${id}&page=${page}&date=${date}&date2=${date2}`, config);

        dispatch({ type: GET_LOGS_SUCCESS, payload: data.data });

    } catch (error) {
        dispatch({
            type: GET_LOGS_FAIL,
            payload: error?.response?.data.message,
        });
    }
};


export const clearError = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERROR });
};

