import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/loading";
import Sidebar from "../../components/Sidebar";
// import "./StudentUpdate.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DocumentUploadUi from '../../components/DocumentUploadUi.jsx';
import { clearError, createResourceAction } from "../../actions/resourceAction.js";
import { CREATE_RESOURCE_RESET } from "../../constants/resourceConstant.js";
import RoleSelect from "./rolesUI.js";
import UsersSelect from "./UsersSelectUI.js";






const StudentUpdate = () => {
    const dispatch = useDispatch();

    const { error, isUpdated, loading } = useSelector(
        (state) => state.resourceUpdate
    );

    const navigate = useNavigate();
    const { user } = useSelector((state) => state.users);


    const [fields, setFields] = useState([
        // { message: "", file: null, fileType: "" }
    ]);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);



    const [resourceData, setResourceData] = useState({
        message: '', link: ''
    })

    const {
        message, link
    } = resourceData;


    const studentCreateHandler = async (e) => {
        e.preventDefault();

        let ids = []
        users.forEach(item => {
            ids.push(item.value)
        });

        let myRoles = []
        roles.forEach(item => {
            myRoles.push(item.value)
        });


        let data = {
            textMessage: message,
            link,
            roles: user?.role === 'admin' ? myRoles : ['admin'],
            ids: user?.role === 'admin' ? ids : [],
            documentsFiles: fields
        };

        // console.log(data)


        dispatch(createResourceAction(data));
    };



    const studentDataChangeHandler = e => {
        setResourceData({ ...resourceData, [e.target.name]: e.target.value })
    }


    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
            // setSelectedMultiFile([])
        }
        if (isUpdated) {
            toast.success("Resource has sent Successfully");
            // if(user?.role==='counsellor'){
            // }else{
            // }
            dispatch({ type: CREATE_RESOURCE_RESET });
            navigate(-1);
        }
    }, [error, isUpdated, dispatch]);

    return (
        <div className="dashboardMain">
            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentUpdateBox">
                        <form action="" onSubmit={studentCreateHandler}>
                            <h3>Create New Resource</h3>


                            <>
                                <div className="assignContainer">
                                    {
                                        user?.role === 'admin' ?
                                        <>
                                            <RoleSelect setRoles={setRoles} roles={roles} />
                                            <UsersSelect setUsers={setUsers} users={users} />
                                        </>:
                                        <div>
                                            <p>Update Send to : [ Admins ]</p>
                                        </div>
                                    }

                                </div>

                                <br />



                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p> Link</p>
                                        <input
                                            name="link"
                                            type="text" placeholder="value"
                                            value={link}
                                            onChange={studentDataChangeHandler}
                                        />
                                    </div>
                                </div>

                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Text message</p>

                                        <textarea name="message"
                                            placeholder="value"
                                            value={message}
                                            onChange={studentDataChangeHandler}
                                            id="" className="textArea"></textarea>
                                    </div>

                                </div>












                                <br />


                            </>


                            <DocumentUploadUi setFields={setFields} fields={fields} title="Upload Files" />



                            <div className="submitItem">
                                <input type="submit" value={"Create Resource"} />
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentUpdate;
