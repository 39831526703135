import {
  GET_STUDENT_REQUEST,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAIL,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAIL,
  UPLOAD_FILE_RESET,
  CLEAR_ERROR,
  GET_FILE_REQUEST,
  GET_FILE_SUCCESS,
  GET_FILE_FAIL,
  STUDENT_UPDATE_REQUEST,
  STUDENT_UPDATE_SUCCESS,
  STUDENT_UPDATE_FAIL,
  STUDENT_UPDATE_RESET,
  GET_STUDENT_DETAIL_REQUEST,
  GET_STUDENT_DETAIL_SUCCESS,
  GET_STUDENT_DETAIL_FAIL,
  STUDENT_ASIGN_REQUEST,
  STUDENT_ASIGN_RESET,
  STUDENT_ASIGN_SUCCESS,
  STUDENT_ASIGN_FAIL,
  GET_ASIGN_APPLICATION_REQUEST,
  GET_ASIGN_APPLICATION_SUCCESS,
  GET_ASIGN_APPLICATION_FAIL,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAIL,
  CREATE_STUDENT_REQUEST,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAIL,
  CREATE_STUDENT_RESET,
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_FAIL,
  GET_APPLICATIONS_SUCCESS,
  GET_PROCESSED_APPLICATION_REQUEST,
  GET_PROCESSED_APPLICATION_SUCCESS,
  GET_PROCESSED_APPLICATION_FAIL,
  GET_ASIGN_APPLICATION_HISTORY_REQUEST,
  GET_ASIGN_APPLICATION_HISTORY_SUCCESS,
  GET_ASIGN_APPLICATION_HISTORY_FAIL,
  GET_LEADS_REQUEST,
  GET_LEADS_SUCCESS,
  GET_LEADS_FAIL,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL
} from '../constants/fileConstant'


export const fileUpload = (state = { file: {} }, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
      return {
        loading: true,
        isUploaded: false,
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUploaded: true,
        file: action.payload,
      };


    case UPLOAD_FILE_FAIL:
      return {
        ...state,
        loading: false,
        isUploaded: false,
        file: null,
        error: action.payload,
      };
    case UPLOAD_FILE_RESET:
      return {
        ...state,
        loading: false,
        isUploaded: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const userReport = (state = { file: {} }, action) => {
  switch (action.type) {
    case GET_REPORT_REQUEST:
      return {
        loading: true,
      };

    case GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };


    case GET_REPORT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const getStudents = (state = { students: [] }, action) => {
  switch (action.type) {
    case GET_STUDENT_REQUEST:
      case GET_LEADS_REQUEST:
    case GET_FILE_REQUEST:
      return {
        loading: true,
      };

    case GET_STUDENT_SUCCESS:
    case GET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload.students,
        resultPerPage: action.payload.resultPerPage,
        totalfilterStudents: action.payload.totalfilterStudents
      };

      case GET_LEADS_SUCCESS:
        return {
          ...state,
          loading: false,
          students: action.payload.students,
          resultPerPage: action.payload.resultPerPage,
          totalfilterStudents: action.payload.totalfilterStudents
        };
  

    case GET_STUDENT_FAIL:
    case GET_STUDENT_FAIL:
      case GET_LEADS_FAIL:
      return {
        ...state,
        loading: false,
        students: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getStudent = (state = { student: {} }, action) => {
  switch (action.type) {
    case GET_STUDENT_DETAIL_REQUEST:
      return {
        loading: true,
      };

    case GET_STUDENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        student: action.payload.student,
        assigned_user: action.payload.assigned_user,
        upload_by_user: action.payload.upload_by_user,
        assUsers:action.payload.assUsers
      };


    case GET_STUDENT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        students: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getFiles = (state = { students: [] }, action) => {
  switch (action.type) {
    case GET_FILE_REQUEST:
      return {
        loading: true,
      };

    case GET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload,
      };


    case GET_FILE_FAIL:
      return {
        ...state,
        loading: false,
        students: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getDashboard = (state = { data: {} }, action) => {
  switch (action.type) {
    case GET_DASHBOARD_REQUEST:
      return {
        loading: true,
      };

    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };


    case GET_DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export const studentUpdate = (state = { file: {} }, action) => {
  switch (action.type) {
    case STUDENT_UPDATE_REQUEST:
    case CREATE_STUDENT_REQUEST:
      return {
        loading: true,
        isUpdated: false,
      };

    case STUDENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
        student: action.payload,
      };

    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: true,
      };


    case STUDENT_UPDATE_FAIL:
    case CREATE_STUDENT_FAIL:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        student: null,
        error: action.payload,
      };

    case STUDENT_UPDATE_RESET:
    case CREATE_STUDENT_RESET:
      return {
        ...state,
        loading: false,
        isUpdated: false,
        error: null,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const asign = (state = { asign: {} }, action) => {
  switch (action.type) {
    case STUDENT_ASIGN_REQUEST:
      return {
        loading: true,
        isAsigned: false,
      };

    case STUDENT_ASIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAsigned: true,
        message: action.payload,
      };


    case STUDENT_ASIGN_FAIL:
      return {
        ...state,
        loading: false,
        isAsigned: false,
        message: null,
        error: action.payload,
      };
    case STUDENT_ASIGN_RESET:
      return {
        ...state,
        loading: false,
        isAsigned: false,
        error: null,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const getAsignedApplications = (state = { students: [] }, action) => {
  switch (action.type) {
    case GET_ASIGN_APPLICATION_REQUEST:
      case GET_APPLICATIONS_REQUEST:
        case GET_PROCESSED_APPLICATION_REQUEST:
          case GET_ASIGN_APPLICATION_HISTORY_REQUEST:
        
      return {
        loading: true,
      };

      case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload.students,
        totalfilterStudents: action.payload.totalfilterStudents,
        resultPerPage: action.payload.resultPerPage,
        user: action.payload.user,
      };

      case GET_ASIGN_APPLICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload.students,
        totalfilterStudents: action.payload.totalfilterStudents,
        resultPerPage: action.payload.resultPerPage,
      };

      case GET_PROCESSED_APPLICATION_SUCCESS:
        return {
          ...state,
          loading: false,
          students: action.payload.students,
          totalfilterStudents: action.payload.totalfilterStudents,
          resultPerPage: action.payload.resultPerPage,
        };

        case GET_ASIGN_APPLICATION_HISTORY_SUCCESS:
          return {
            ...state,
            loading: false,
            students: action.payload.students,
            totalfilterStudents: action.payload.totalfilterStudents,
            resultPerPage: action.payload.resultPerPage,
          };


    case GET_ASIGN_APPLICATION_FAIL:
      case GET_PROCESSED_APPLICATION_FAIL:
      case GET_APPLICATIONS_FAIL:
        case GET_ASIGN_APPLICATION_HISTORY_FAIL
        :
      return {
        ...state,
        loading: false,
        students: null,
        error: action.payload,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
