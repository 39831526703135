import './loginLoading.css'

import React from 'react'

const Loading = () => {
  return (
    <div className='login-loading'>
      {/* <div> */}
      {/* <img src={require('../../images/gleam_logo.png')} alt="GLEAM APP" /> */}
      {/* </div> */}
      {/* <MetaData title="Loading..." /> */}
      <div></div>
    </div>
  )
}

export default Loading