import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { MdEmail } from "react-icons/md";
// import "./login.css";

import { useDispatch, useSelector } from "react-redux";
import { clearError, forgetPassword } from '../../actions/userAction'
import { toast } from "react-toastify";
import Loading from "../../components/Loading/loading";
import { FORGOT_PASSWORD_RESET } from "../../constants/userConstant";

const Register = () => {
  //   const { error, loading, isAuthenticated } = useSelector((state) => state.users)
  const { error, message, loading } = useSelector((state) => state.forgetPassword);

  const [email, setEmail] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      toast.error(error)
      dispatch(clearError())
    }
    if (message) {
      toast.success(message)
      navigate("/")
      dispatch({ type:FORGOT_PASSWORD_RESET})
      // dispatch(clearError())
    }

  }, [error, message]);




  const forgetPasswordSubmit = (e) => {
    e.preventDefault()

    dispatch(forgetPassword(email));
  }


  return (

    // {
    //     loading :
    // }
    <Fragment>
      {
        loading ? <Loading /> : (
          <div className="loginContainer">
            <div className="imgBox">
              <img src={require("../../images/sign-up.webp")} alt="image" />
            </div>
            <div>
              <div className="formBox">
                <h2>Forget Password </h2>
                <form action="" onSubmit={(e) => forgetPasswordSubmit(e)}>

                  <div>
                    <MdEmail />
                    <input type="email" className="logInpBtn" required placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                  </div>

                  <div>
                    <input type="submit" className="submintBtn" value="Send Link" />
                  </div>
                </form>
                <div className="regBox">
                  {/* <p>I m New </p> */}

                  <Link className="submintBt" to={"/"}>
                    Log in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </Fragment>
  );
};

export default Register;
