import { CLEAR_ERROR, CREATE_COMMENT_FAIL, CREATE_COMMENT_REQUEST, CREATE_COMMENT_RESET, CREATE_COMMENT_SUCCESS, DELETE_COMMENT_FAIL, DELETE_COMMENT_REQUEST, DELETE_COMMENT_RESET, DELETE_COMMENT_SUCCESS, GET_COMMENTS_FAIL, GET_COMMENTS_REQUEST, GET_COMMENTS_SUCCESS, UPDATE_COMMENT_FAIL, UPDATE_COMMENT_REQUEST, UPDATE_COMMENT_RESET, UPDATE_COMMENT_SUCCESS } from '../constants/commentConstant'
  
  
  export const updateComment = (state = { file: {} }, action) => {
    switch (action.type) {
      case CREATE_COMMENT_REQUEST:
        case UPDATE_COMMENT_REQUEST:
            case DELETE_COMMENT_REQUEST:
        return {
          loading: true,
          isUpdated: false,
        };
  
      case CREATE_COMMENT_SUCCESS:
      case UPDATE_COMMENT_SUCCESS:
        case DELETE_COMMENT_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: true,
          success: true,
          message: action.payload.message,
        };
  
  
      case CREATE_COMMENT_FAIL:
      case UPDATE_COMMENT_FAIL:
        case DELETE_COMMENT_FAIL:
        return {
          ...state,
          loading: false,
          isUpdated: false,
          success: null,
          error: action.payload,
        };
      case CREATE_COMMENT_RESET:
      case UPDATE_COMMENT_RESET:
        case DELETE_COMMENT_RESET:
        return {
          ...state,
          loading: false,
          isUpdated: false,
          error: null,
          success: null,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export const getComments = (state = { comments: [] }, action) => {
    switch (action.type) {
      case GET_COMMENTS_REQUEST:
        return {
          loading: true,
        };
  
      case GET_COMMENTS_SUCCESS:
        return {
          ...state,
          loading: false,
          comments: action.payload.comments,
          resultPerPage: action.payload.resultPerPage,
          totalComments: action.payload.totalComments
        };
  
      case GET_COMMENTS_FAIL:
        return {
          ...state,
          loading: false,
          comments: null,
          error: action.payload,
        };
  
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  
  