export const GET_MONITOR_USERS_REQUEST = "GET_MONITOR_USERS_REQUEST"
export const GET_MONITOR_USERS_SUCCESS = "GET_MONITOR_USERS_SUCCESS"
export const GET_MONITOR_USERS_FAIL = "GET_MONITOR_USERS_FAIL"

export const GET_SCREEN_TRACKS_REQUEST = "GET_SCREEN_TRACKS_REQUEST"
export const GET_SCREEN_TRACKS_SUCCESS = "GET_SCREEN_TRACKS_SUCCESS"
export const GET_SCREEN_TRACKS_FAIL = "GET_SCREEN_TRACKS_FAIL"

export const GET_SCREEN_TRACK_REQUEST = "GET_SCREEN_TRACK_REQUEST"
export const GET_SCREEN_TRACK_SUCCESS = "GET_SCREEN_TRACK_SUCCESS"
export const GET_SCREEN_TRACK_FAIL = "GET_SCREEN_TRACK_FAIL"

export const CREATE_SCREEN_TRACKS_REQUEST = "CREATE_SCREEN_TRACKS_REQUEST"
export const CREATE_SCREEN_TRACKS_SUCCESS = "CREATE_SCREEN_TRACKS_SUCCESS"
export const CREATE_SCREEN_TRACKS_FAIL = "CREATE_SCREEN_TRACKS_FAIL"
export const CREATE_SCREEN_TRACKS_RESET = "CREATE_SCREEN_TRACKS_RESET"

export const GET_MONITOR_USER_REQUEST = "GET_MONITOR_USER_REQUEST"
export const GET_MONITOR_USER_SUCCESS = "GET_MONITOR_USER_SUCCESS"
export const GET_MONITOR_USER_FAIL = "GET_MONITOR_USER_FAIL"

export const GET_SCREENSHOTS_REQUEST = "GET_SCREENSHOTS_REQUEST"
export const GET_SCREENSHOTS_SUCCESS = "GET_SCREENSHOTS_SUCCESS"
export const GET_SCREENSHOTS_FAIL = "GET_SCREENSHOTS_FAIL"

export const GET_RECORDINGS_REQUEST = "GET_RECORDINGS_REQUEST"
export const GET_RECORDINGS_SUCCESS = "GET_RECORDINGS_SUCCESS"
export const GET_RECORDINGS_FAIL = "GET_RECORDINGS_FAIL"

export const GET_APPS_REQUEST = "GET_APPS_REQUEST"
export const GET_APPS_SUCCESS = "GET_APPS_SUCCESS"
export const GET_APPS_FAIL = "GET_APPS_FAIL"

export const GET_LOGS_REQUEST = "GET_LOGS_REQUEST"
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS"
export const GET_LOGS_FAIL = "GET_LOGS_FAIL"

export const CLEAR_ERROR = "CLEAR_ERROR"