import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { clearError, getAllUsersForAssign } from '../../actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const RoleSelect = ({users, setUsers}) => {

//   let rolesOptions = [
    
//   ];
  const [rolesOptions, setRolesOptions] = useState([]);


  const dispatch = useDispatch();

  const { error, users:selectUsers, loading } = useSelector(
    (state) => state.allUsers
  );

  

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError());
    }
   
    
    dispatch(getAllUsersForAssign())

  }, [error,  dispatch]);



  const formattedRoleOptions = selectUsers.map((role) => ({
    value: role._id,
    label:`${ role.name} - ${ role.email}`,
  }));




  const handleChange = (selectedOptions) => {
    setUsers(selectedOptions);
  };

  return (
    <div className='updateInputItem'>
      <p>Select Users</p>
      <Select
        isMulti
        options={formattedRoleOptions}
        value={users}
        onChange={handleChange}
        placeholder="Select users..."
      />
    </div>
  );
};

export default RoleSelect;
