import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading/loading";
import Sidebar from "../../components/Sidebar";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { clearError, getUniversityAction, updateUniversityAction } from "../../actions/universityAction.js";
import DocumentUploadUi from '../../components/DocumentUploadUi.jsx';
import { STUDENT_UPDATE_RESET } from "../../constants/fileConstant";
import { UPDATE_UNIVERSITY_RESET } from "../../constants/universityConstant.js";





const StudentUpdate = () => {
    const dispatch = useDispatch();
    const { error, isUpdated, loading } = useSelector(
        (state) => state.universityUpdate
    );
    const {
        error: StudentError,
        university,
        loading: studentLoaging,
    } = useSelector((state) => state.getUniversity);


    const [fields, setFields] = useState([
        // { name: "", file: null, fileType: "" }
    ]);


    const { user } = useSelector((state) => state.users);


    const { id } = useParams();
    const navigate = useNavigate();



    const [name, setName] = useState("");
    const [country, setCountry] = useState("");

    const [courseLink, setCourseLink] = useState("");
    const [appSubmitLink, setAppSubmitLink] = useState("");







    useEffect(() => {

        setName(university?.name)
        setCountry(university?.country)
        setAppSubmitLink(university?.appSubmitLink)
        setCourseLink(university?.courseLink)

    }, [studentLoaging]);



    const studentUpdateHandler = async (e) => {
        e.preventDefault();


        let data = {
            name, courseLink, country, appSubmitLink
        };


        dispatch(updateUniversityAction(id, { bodyData: data, documentsFiles:fields }));
    };




    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearError());
            // setSelectedMultiFile([])
        }
        if (isUpdated) {
            toast.success("Updated Successfully");
            navigate(-1);
            dispatch({ type: UPDATE_UNIVERSITY_RESET });
        }
        dispatch(getUniversityAction(id))

        if (StudentError) {
            toast.error(StudentError)
            dispatch(clearError())

        }
    }, [error, isUpdated, dispatch, StudentError]);

    return (
        <div className="dashboardMain">
            <div
                className={
                    window.innerWidth > 600 ? "menuContainer show" : "menuContainer hide"
                }
            >
                <Sidebar />
            </div>
            {loading ? (
                <Loading />
            ) : (
                <div className="dashboardContainer">
                    {/* <DashboardHeader heading={"Students Application"} /> */}

                    <div className="StudentUpdateBox">
                        <form action="" onSubmit={studentUpdateHandler}>
                            <h3>Update - University Details </h3>

                            <>
                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>University Name</p>
                                        <input
                                            type="text"
                                            required
                                            value={name}
                                            name="name" placeholder="value"
                                            onChange={e => setName(e.target.value)} />
                                    </div>

                                </div>



                                <div className="updateItemBox">

                                    <div className="updateInputItem">
                                        <p>Country </p>
                                        <input
                                            type="text"
                                            value={country}
                                            name="country" placeholder="value"
                                            onChange={e => setCountry(e.target.value)}
                                        />
                                    </div>
                                </div>





                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Course Link</p>
                                        <input
                                            name="courseLink"
                                            type="text" placeholder="value"
                                            value={courseLink}
                                            onChange={e => setCourseLink(e.target.value)}
                                        />
                                    </div>
                                </div>


                                <div className="updateItemBox">
                                    <div className="updateInputItem">
                                        <p>Application Submit Link</p>
                                        <input
                                            name="appSubmitLink" placeholder="value"
                                            type="text"
                                            value={appSubmitLink}
                                            onChange={e => setAppSubmitLink(e.target.value)}
                                        />
                                    </div>
                                </div>




                                <br />


                            </>





                            {/* file upload code */}

                            <DocumentUploadUi setFields={setFields} fields={fields} />


                            <div className="submitItem">
                                <input type="submit" value={"Update"} />
                            </div>
                        </form>
                    </div>
                </div>
            )
            }
        </div >
    );
};

export default StudentUpdate;
