import React, { useState } from "react";
import './documentUploadUi.css'

const MAX_FILE_SIZE_MB = 50;

const FileInputForm = ({ setFields, fields ,title}) => {

    console.log(fields)

    // Valid file types
    const validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
        "application/msword", // doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
        "text/html" // htm and html
    ];

    const handleAddField = () => {
        setFields([...fields, { name: "", file: null, fileType: "" }]);
    };

    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };

    const handleFieldChange = (index, event) => {
        const { name, value, files } = event.target;
        const updatedFields = [...fields];

        if (name === "file" && files.length > 0) {
            const file = files[0];
            const fileType = file.type;

            if (validFileTypes.includes(fileType)) {
                if (checkFileSize(file)) {
                    storeFileInField(index, file, updatedFields, fileType);
                }
            } else {
                alert("Invalid file type. Please select a jpg, jpeg, png, pdf, doc, or docx file.");
            }
        } else {
            updatedFields[index][name] = value;
            setFields(updatedFields);
        }
    };

    // Check if file size is less than 10MB
    const checkFileSize = (file) => {
        const fileSizeMB = file.size / (1024 * 1024); // Convert to MB
        if (fileSizeMB > MAX_FILE_SIZE_MB) {
            alert("File size exceeds 10MB. Please choose a smaller file.");
            return false;
        }
        return true;
    };

    // Use FileReader to store file result in 'file' field
    const storeFileInField = (index, file, updatedFields, fileType) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            updatedFields[index].file = reader.result; // Store the base64 or binary result
            updatedFields[index].fileType = fileType;
            setFields(updatedFields);
        };
        reader.readAsDataURL(file); // Read the file as a Data URL
    };

    return (
        <div className="doc-upload-container">
            <h3>{title ? title : "Upload Documents File "}</h3>

            {fields.map((field, index) => (
                <div style={{ marginBottom: "20px" }}>
                    <div key={index} className="d-u-box">
                        <p>{index + 1}</p>
                       
                        <input
                            type="file"
                            name="file"
                            required

                            accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                            onChange={(e) => handleFieldChange(index, e)}
                            className="nameInpFile"
                        />
                         <input
                            type="text"
                            name="name"
                            placeholder="File Name"
                            value={field.name}
                            required
                            onChange={(e) => handleFieldChange(index, e)}
                            style={{ marginRight: "10px" }}
                            className="nameInp"
                        />
                        <button
                            type="button"
                            onClick={() => handleRemoveField(index)}
                            className="cancelBtn"
                        >
                            Cancel
                        </button>
                    </div>
                    <p className="warn">Accepted Only : jpg, jpeg, png, docx, pdf, html, doc.  | max-size : {MAX_FILE_SIZE_MB} mb</p>
                </div>

            ))}
            <button type="button" className="addBtn" onClick={handleAddField}>
                Add File
            </button>
            {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
        </div>
    );
};

export default FileInputForm;
